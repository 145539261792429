import {
    ActionIcon,
    Breadcrumbs,
    Button,
    Checkbox,
    ComboboxOption,
    createTheme,
    CSSVariablesResolver,
    DEFAULT_THEME,
    Group,
    Input,
    InputBase,
    Menu,
    mergeMantineTheme,
    Modal,
    Notification,
    NumberInput,
    rem,
    Select,
    Tabs,
    Text,
    Textarea,
    TextInput,
    Tooltip,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconCheck } from "@tabler/icons-react";
import comboboxOptionClassnames from "shared/mantine/ComboboxOption.module.css";
import { match } from "ts-pattern";

const themeOverride = createTheme({
    autoContrast: true,
    black: "#150303",
    colors: {
        blue: ["#e7f6ff", "#d0e8ff", "#9fcffc", "#6ab4fb", "#439dfa", "#2d8ffa", "#2188fb", "#1375e1", "#0068c9", "#0059b2"],
        gray: ["#F1F1F1", "#EBEBEB", "#B0B0B0", "#8F8F8F", "#878787", "#737373", "#6F6767", "#484141", "#332E2E", "#292929"],
        green: ["#E2FEF6", "#87F5D4", "#42E6B5", "#00D696", "#00BC83", "#00A170", "#008059", "#006647", "#004B31", "#002619"],
        orange: ["#FCF4ED", "#FFD4AF", "#FFA768", "#FF7C1F", "#EB721B", "#D86512", "#C25100", "#B14A00", "#552A0C", "#36180D"],
        red: ["#FFF9F9", "#FAE5E5", "#FFADAD", "#FF6565", "#F32020", "#C61A1B", "#8F0808", "#620D0D", "#4F0E13", "#230808"],
    },
    components: {
        ActionIcon: ActionIcon.extend({
            defaultProps: {
                size: "md",
            },
            vars: (_, props) => {
                if (props.size == "lg") return { root: { "--ai-size": rem(34) } };
                if (props.size == "xl") return { root: { "--ai-size": rem(42) } };
                return { root: {} };
            },
        }),
        Breadcrumbs: Breadcrumbs.extend({
            defaultProps: {
                separator: ">",
                separatorMargin: "md",
            },
        }),
        Button: Button.extend({
            defaultProps: {
                size: "md",
            },
            vars: (_, props) => {
                return match(props.size)
                    .with("sm", () => ({
                        root: {
                            "--button-fz": rem(12),
                        },
                    }))
                    .with("md", () => ({
                        root: {
                            "--button-fz": rem(14),
                        },
                    }))
                    .with("lg", () => ({
                        root: {
                            "--button-fz": rem(16),
                        },
                    }))
                    .otherwise(() => ({ root: {} }));
            },
        }),
        DateInput: DateInput.extend({
            defaultProps: {
                styles: { input: { fontSize: "var(--mantine-font-size-lg)" } },
                valueFormat: "MM/DD/YYYY",
            },
        }),
        Select: Select.extend({
            defaultProps: {
                fz: "lg",
                renderOption({ option, checked }) {
                    return (
                        <Group flex={1} justify="space-between" gap="xs">
                            <Text c={checked ? "red" : undefined}>{option.label}</Text>
                            {checked && <IconCheck color={"var(--mantine-primary-color-filled)"} size={16} />}
                        </Group>
                    );
                },
            },
        }),
        ComboboxOption: ComboboxOption.extend({
            defaultProps: {
                classNames: comboboxOptionClassnames,
                fz: "lg",
            },
        }),
        Checkbox: Checkbox.extend({
            defaultProps: {
                size: "sm",
            },
        }),
        NumberInput: NumberInput.extend({
            styles: { input: { fontSize: "var(--mantine-font-size-lg)" } },
        }),
        InputLabel: Input.Label.extend({
            defaultProps: {
                mb: "xs",
                fz: "lg",
            },
        }),
        TextInput: TextInput.extend({
            defaultProps: {
                styles: { input: { fontSize: "var(--mantine-font-size-lg)" } },
            },
        }),
        MenuItem: Menu.Item.extend({
            defaultProps: {
                fz: "md",
                p: "md",
            },
        }),
        Notification: Notification.extend({
            defaultProps: {
                miw: rem(300),
                p: "md",
            },
        }),
        TabsTab: Tabs.Tab.extend({
            defaultProps: {
                fz: rem(16),
                px: "xl",
                py: "md",
            },
        }),
        Textarea: Textarea.extend({
            defaultProps: {
                minRows: 5,
                autosize: true,
            },
        }),
        InputBase: InputBase.extend({
            styles: {
                input: {
                    fontSize: rem(16),
                },
            },
        }),
        Tooltip: Tooltip.extend({
            defaultProps: {
                p: "xs",
            },
        }),
        ModalBody: Modal.Body.extend({
            defaultProps: {
                pb: "sm",
                px: "lg",
            },
        }),
    },
    fontFamily: "Open Sans",
    fontSizes: {
        xs: rem(10),
        sm: rem(12),
        md: rem(14),
        lg: rem(16),
        xl: rem(18),
    },
    other: {
        width: {
            input: {
                sm: rem(106),
                md: rem(189),
                lg: rem(402),
                xl: rem(768),
            },
        },
        colors: {
            bg: {
                graphics: "var(--rms-bg-graphics)",
                secondary: "var(--rms-bg-secondary)",
            },
            text: {
                secondary: "var(--rms-color-text-secondary)",
            },
        },
    },
    headings: {
        sizes: {
            h1: {
                fontWeight: "400",
                fontSize: rem(31),
                lineHeight: rem(44),
            },
            h2: {
                fontWeight: "400",
                fontSize: rem(25),
                lineHeight: rem(36),
            },
            h3: {
                fontWeight: "600",
                fontSize: rem(20),
                lineHeight: rem(24),
            },
            h4: {
                fontWeight: "700",
                fontSize: rem(16),
                lineHeight: rem(22),
            },
            h5: {
                fontWeight: "600",
                fontSize: rem(16),
                lineHeight: rem(22),
            },
            h6: {
                fontWeight: "700",
                fontSize: rem(14),
                lineHeight: rem(18),
            },
        },
    },
    lineHeights: {
        xs: rem(12),
        sm: rem(16),
        md: rem(18),
        lg: rem(20),
        xl: rem(22),
    },
    primaryColor: "red",
    primaryShade: {
        dark: 3,
        light: 6,
    },
    spacing: {
        xs: rem(4),
        sm: rem(8),
        md: rem(12),
        lg: rem(16),
        xl: rem(20),
        "2xl": rem(24),
        "3xl": rem(32),
        "4xl": rem(40),
        "5xl": rem(48),
    },
    white: "#FCFCFC",
});

// TODO: Make theme type strict
// https://mantine.dev/guides/typescript/#theme-object-declarations
// https://github.com/orgs/mantinedev/discussions/4966#discussioncomment-8218580
export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
    variables: {
        "--fc-today-bg-color": "transparent",
    },
    light: {
        "--ai-color": theme.colors.gray[4],
        "--mantine-color-success": theme.colors.green[6],
        "--mantine-color-error": theme.colors.orange[6],
        "--mantine-color-text": theme.black,
        "--mantine-color-text-secondary": theme.colors.gray[5],
        "--rms-sidebar-bg": theme.colors.red[9],
        "--rms-sidebar-bg--active": "#7E5858",
        "--rms-sidebar-text": "#CAB9B9",
        "--rms-page-bg": theme.white,
        "--rms-bg-secondary": theme.colors.gray[1],
        "--rms-color-text-secondary": theme.colors.gray[6],
        "--rms-bg-graphics": theme.colors.gray[4],
        "--mantine-color-primary-color": theme.colors.red[6],
        "--mantine-color-primary-1": theme.colors.red[1],
        "--mantine-color-primary-1-inverted": theme.colors.red[8],
        "--mantine-color-cta": theme.colors.red[5],
    },
    dark: {
        "--ai-color": theme.colors.gray[3],
        "--mantine-color-success": theme.colors.green[3],
        "--mantine-color-error": theme.colors.orange[3],
        "--mantine-color-text": theme.white,
        "--mantine-color-text-secondary": theme.colors.gray[2],
        "--rms-sidebar-bg": theme.colors.red[8],
        "--rms-sidebar-bg--active": "#AD5858",
        "--rms-sidebar-text": "#D6A9A9",
        "--rms-page-bg": theme.black,
        "--rms-bg-secondary": theme.colors.gray[8],
        "--rms-color-text-secondary": theme.colors.gray[3],
        "--rms-bg-graphics": theme.colors.gray[3],
        "--mantine-color-primary-color": theme.colors.red[3],
        "--mantine-color-primary-1": theme.colors.red[8],
        "--mantine-color-primary-1-inverted": theme.colors.red[1],
        "--mantine-color-cta": theme.colors.red[4],
    },
});
