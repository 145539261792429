import { Button, Divider, Group, rem, Stack, Text, Title } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import { ContextModalProps } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { maybe } from "@tsly/maybe";

type DeleteModalProps = ContextModalProps<{
    id: string;
    handle: (id: string, options: { onSettled: () => unknown }) => unknown;
    queryKey?: string[];
    altText?: string;
    altSubtext?: string;
    submitText?: string;
}>;
export const DeleteModal = ({ context, id: modalId, innerProps: props }: DeleteModalProps) => {
    const queryClient = useQueryClient();

    async function submit() {
        await new Promise<void>((onSettled) => props.handle(props.id, { onSettled }));
        await maybe(props.queryKey)?.take((queryKey) =>
            queryClient.invalidateQueries({
                queryKey,
            }),
        );
        context.closeModal(modalId);
    }

    useHotkeys([["Enter", () => submit()]]);

    return (
        <Stack gap="lg">
            <Stack align="center">
                <img src="/trash-graphic.svg" />
                <Title order={2}>{props.altText ?? `Delete this record?`}</Title>
                <Text mt="2xl" ta="center" maw={rem(340)}>
                    {props.altSubtext ?? `You are about to delete this record. Are you sure you want to do this?`}
                </Text>
            </Stack>
            <Divider mx={-16} mb={-8} />
            <Stack align="flex-end">
                <Group>
                    <Button variant="subtle" onClick={() => void submit()}>
                        {props.submitText ?? "Delete"}
                    </Button>
                    <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
                        Cancel
                    </Button>
                </Group>
            </Stack>
        </Stack>
    );
};
