import { IconFolderCog } from "@tabler/icons-react";
import AuditSpec from "modules/Audit/_spec";
import EventSubTypeSpec from "modules/EventSubTypes/_spec";
import HydrantSettingsSpec from "modules/HydrantSettings/_spec";
import IncidentTypeSpec from "modules/IncidentType/_spec";
import PointsSpec from "modules/PointSystems/_spec";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Management",
    slug: "management",
    icon: {
        default: IconFolderCog,
        filled: IconFolderCog,
    },
    nestedModules: [AuditSpec, EventSubTypeSpec, HydrantSettingsSpec, IncidentTypeSpec, PointsSpec],
    queryKeys: {},
    sidebar: { visibilityRequiresReadAccessToAnyOf: ["organization"] },
    ingresses: {},
    routes: [],
} as const satisfies ModuleSpec;
