import { AppShell, Stack } from "@mantine/core";
import { pipe } from "@tsly/core";
import { maybe } from "@tsly/maybe";
import { obj } from "@tsly/obj";
import { ColorModeController } from "modules/Core/ColorModeController";
import { ErrorBoundary } from "modules/Core/ErrorBoundary";
import { registeredModuleSpecs } from "modules/Core/registrar";
import { withSessionGuard } from "modules/Core/router/guards";
import { SidebarNav } from "modules/Core/sidebar/SidebarNav";
import { Suspense, useEffect } from "react";
import { createPortal } from "react-dom";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import { useAccountPermissions } from "shared/api/accountApi";
import { useOrganization } from "shared/api/organizationApi";
import { PageLoader } from "shared/components/page/PageLoader";
import { ToastContainer } from "shared/notifications/ToastContainer";
import { useUserStore } from "shared/stores/userStore";
import { useActiveModuleSpec } from "shared/utils/module";

export const externalPaths = {
    CONTACT: "https://bryx-20192523.hs-sites.com/contact-us", // :bruh-intensifies:
};

const Container = () => {
    const curSpec = useActiveModuleSpec();
    const userId = useUserStore((s) => s.user.id);
    const organizationId = useUserStore((s) => s.currentOrganization.id);
    const { setCurrentOrganization, setUserAuthority } = useUserStore.getState();

    const navbarWidth = 250;

    const { data: organization } = useOrganization(organizationId, {
        enabled: Boolean(organizationId),
    });

    const { data: user } = useAccountPermissions(userId, {
        enabled: Boolean(userId) && Boolean(organizationId),
    });

    useEffect(() => {
        organization && setCurrentOrganization(organization);
        user && setUserAuthority(user);
    }, [organization, user]);

    if (maybe(curSpec)?.take((curSpec) => !!obj(curSpec).getUntypedProperty("noShell")))
        return (
            <ColorModeController>
                {createPortal(<ToastContainer />, document.getElementsByTagName("body")[0])}
                <Suspense fallback={<PageLoader />}>
                    <Outlet />
                </Suspense>
            </ColorModeController>
        );

    return withSessionGuard(
        <ColorModeController>
            {createPortal(<ToastContainer />, document.getElementsByTagName("body")[0])}
            <AppShell navbar={{ width: navbarWidth, breakpoint: "xs" }}>
                <SidebarNav />
                <AppShell.Main bg="var(--rms-page-bg)" pl={navbarWidth} pr={0}>
                    <Stack h="100vh" style={{ overflowY: "scroll" }} gap={0}>
                        <Suspense fallback={<PageLoader />}>
                            <Outlet />
                        </Suspense>
                    </Stack>
                </AppShell.Main>
            </AppShell>
        </ColorModeController>,
    );
};

export const router = pipe(
    <Route path="/" element={<Container />} errorElement={<ErrorBoundary />}>
        {/** @TODO: RMS-1212/RMS-1108 `to="dashboard" */}
        <Route index element={<Navigate replace to="/personnel" />} />
        {registeredModuleSpecs
            .filter((spec) => (import.meta.env.VITE_ENVIRONMENT === "prod" ? !spec.devOnly : true))
            .map((spec, i) => (
                <Route path={`${spec.slug}`} key={i}>
                    {spec.routes}
                </Route>
            ))}
    </Route>,
    createRoutesFromElements,
    createBrowserRouter,
);
