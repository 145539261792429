import { IconCalendar, IconCalendarFilled } from "@tabler/icons-react";
import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Calendar",
    slug: "calendar",
    icon: {
        default: IconCalendar,
        filled: IconCalendarFilled,
    },
    queryKeys: {},
    ingresses: {
        base: () => "" as const,
        newEvent: () => "/new" as const,
        eventDetail: (id: string) => `/${id}` as const,
    },
    routes: [
        <Route
            key="calendarIndex"
            index
            Component={lazy(() => import("modules/Calendar/Calendar").then((module) => ({ default: module.Calendar })))}
        />,
        <Route
            key="calendarEvent"
            path=":eventId"
            Component={lazy(() => import("modules/Calendar/form/EventBuilder").then((module) => ({ default: module.EventBuilder })))}
        />,
    ],
} as const satisfies ModuleSpec;
