import {
    IconBuilding,
    IconBus,
    IconCar,
    IconCircle,
    IconCircleFilled,
    IconCircleKey,
    IconDoor,
    IconDroplet,
    IconElevator,
    IconExclamationCircle,
    IconGasStation,
    IconHeartBolt,
    IconHome2,
    IconHospital,
    IconLock,
    IconPlane,
    IconPlugConnected,
    IconProps,
    IconSchool,
    IconSeeding,
    IconShoppingCart,
    IconStairs,
    IconToolsKitchen2,
} from "@tabler/icons-react";
import { isSome, maybe } from "@tsly/maybe";
import { obj } from "@tsly/obj";
import React from "react";
import { addressSchema } from "shared/types/Common";
import { documentSchema } from "shared/types/Document";
import { moduleZodRawShape, nonemptyString } from "shared/utils/zod";
import { mapToZodLiteralUnion } from "shared/utils/zod";
import { z } from "zod";

export function getPropertyPinTypes() {
    return ["circle", "building", "edu", "med", "house", "leaf", "car", "bus", "airplane", "cart", "food", "gas"] as const;
}

export function getPropertyPinTypeComponent(pinType: PropertyPinType) {
    return {
        circle: IconCircleFilled,
        airplane: IconPlane,
        building: IconBuilding,
        bus: IconBus,
        car: IconCar,
        cart: IconShoppingCart,
        edu: IconSchool,
        food: IconToolsKitchen2,
        gas: IconGasStation,
        house: IconHome2,
        leaf: IconSeeding,
        med: IconHospital,
    }[pinType] as (props: IconProps) => React.JSX.Element;
}

export function getSubPropertyPinType() {
    return ["circle", "aed", "power", "room", "elevator", "key", "stairs", "water", "error", "lock"] as const;
}

export function getSubPropertyPinTypeComponent(pinType: SubPropertyPinType) {
    return {
        circle: IconCircle,
        aed: IconHeartBolt,
        power: IconPlugConnected,
        room: IconDoor,
        elevator: IconElevator,
        key: IconCircleKey,
        stairs: IconStairs,
        water: IconDroplet,
        error: IconExclamationCircle,
        lock: IconLock,
    }[pinType] as (props: IconProps) => React.JSX.Element;
}

export const propertyPinTypeSchema = mapToZodLiteralUnion(getPropertyPinTypes());
export const subPropertyPinTypeSchema = mapToZodLiteralUnion(getSubPropertyPinType());

type PropertyPinType = z.infer<typeof propertyPinTypeSchema>;
type SubPropertyPinType = z.infer<typeof subPropertyPinTypeSchema>;

export const subPropertySchema = z.object({
    id: z.string().default(""),
    rmsAddress: addressSchema,
    name: nonemptyString(),
    description: z.string().nullish(),
    pinType: subPropertyPinTypeSchema.nullish().default("circle"),
    showOnMap: z.boolean().default(false),
});

const subPropertyParamsSchema = subPropertySchema.transform((it) =>
    obj(it)
        .with("id", (id) => (id == "" ? undefined : id))
        .take(),
);

const propertyTableItemSchema = z.object({
    id: z.string(),
    rmsAddress: addressSchema,
    name: z.string(),
    status: z.string().nullish(),
    type: z.string().nullish(),
    isDispatchable: z.boolean().default(false),
    parentId: z.string().nullish(),
});

export const propertySchema = z
    .object({
        name: z.string().nullish(),
        type: z.string().nullish(),
        status: z.string().nullish(),
        notes: z.string().nullish(),
        crossStreets: z.string().nullish(),
        pinType: propertyPinTypeSchema.nullish().default("circle"),
        rmsAddress: addressSchema,
        document: documentSchema.nullish(),
        contactInfos: z.object({ fullName: z.string().nullish(), phoneNumber: z.string().nullish() }).array().default([]),
        pointsOfInterest: subPropertySchema.array().default([]),
        dispatchableAddress: subPropertySchema.array().default([]),
    })
    .extend(obj(moduleZodRawShape).dropKeys(["name", "description", "agencyId"]).take());

export const propertyParamSchema = z.preprocess(
    (it: unknown) =>
        maybe(it)
            .let((it) => z.object({ contactInfos: z.any().array() }).passthrough().parse(it))
            .take((it) => ({
                ...it,
                contactInfos: it.contactInfos.filter((it: Partial<Property["contactInfos"][number]>) => !!it.fullName || !!it.phoneNumber),
            })),
    propertySchema.transform((it) => ({
        ...it,
        dispatchableAddress: it.dispatchableAddress.map((each) => subPropertyParamsSchema.parse(each)),
        pointsOfInterest: it.pointsOfInterest.map((each) => subPropertyParamsSchema.parse(each)),
        contactInfos: it.contactInfos.filter(
            (each) =>
                maybe(each.fullName)
                    ?.unless((it) => it.length == 0)
                    ?.take(isSome) &&
                maybe(each.phoneNumber)
                    ?.unless((it) => it.length == 0)
                    ?.take(isSome),
        ),
        document: it.document?.id,
    })),
);

export type Property = z.infer<typeof propertySchema>;
export type PropertyParams = z.infer<typeof propertyParamSchema>;
export type SubProperty = z.infer<typeof subPropertySchema>;
export type PropertyTableItem = z.infer<typeof propertyTableItemSchema>;
