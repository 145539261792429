import { IconSandbox } from "@tabler/icons-react";
import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: true,
    name: "Sandbox",
    slug: "sandbox",
    icon: {
        default: IconSandbox,
        filled: IconSandbox,
    },
    queryKeys: {},
    ingresses: {
        base: () => "" as const,
    },
    routes: [
        <Route
            key="sandboxIndex"
            index
            Component={lazy(() => import("modules/Sandbox/Sandbox").then((module) => ({ default: module.Sandbox })))}
        />,
    ],
} as const satisfies ModuleSpec;
