import { useMantineTheme } from "@mantine/core";
import { useColorMode } from "shared/hooks/useColorMode";

export const ErrorSvg = () => {
    const theme = useMantineTheme();
    const colorMode = useColorMode();
    const isLight = colorMode === "light";

    return (
        <svg fill="none" width="327" height="264" viewBox="0 0 327 264" xmlns="http://www.w3.org/2000/svg">
            <ellipse
                fill={isLight ? theme.colors.gray[2] : theme.colors.gray[8]}
                cx="162.498"
                cy="140.173"
                rx="145"
                ry="73.26"
                transform="rotate(-22.5 162.498 140.173)"
            />
            <path
                fill="none"
                stroke={isLight ? theme.colors.gray[4] : theme.colors.gray[3]}
                d="M28.9976 74.8751C21.3663 93.2986 29.1986 116.226 48.3274 138.47C67.4355 160.688 97.7182 182.095 134.654 197.394C171.59 212.694 208.14 218.97 237.362 216.77C266.617 214.568 288.367 203.894 295.999 185.471C303.63 167.047 295.798 144.119 276.669 121.876C257.561 99.6573 227.278 78.2507 190.342 62.9514C153.406 47.652 116.856 41.3756 87.6339 43.5753C58.3796 45.7774 36.6289 56.4515 28.9976 74.8751Z"
            />
            <path
                fill={isLight ? theme.white : theme.black}
                stroke={isLight ? theme.colors.red[6] : theme.colors.red[3]}
                d="M100.084 74C100.084 66.268 106.352 60 114.084 60H171.679C176.223 60 180.484 62.2052 183.108 65.9148L224.513 124.445C226.186 126.809 227.084 129.634 227.084 132.53V207C227.084 214.732 220.816 221 213.084 221H114.084C106.352 221 100.084 214.732 100.084 207V74Z"
                strokeWidth="4"
            />
            <path
                fill={isLight ? theme.white : theme.black}
                d="M203.5 201L220.5 183.5L223 210L234.5 223.5L223 233L199.5 229.5L203.5 201Z"
            />
            <path
                fill={isLight ? theme.white : theme.black}
                stroke={isLight ? theme.colors.orange[6] : theme.colors.orange[3]}
                d="M191.213 206.651L191.205 206.671L191.197 206.691C187.52 215.491 190.006 225.641 196.869 232.012C203.075 237.69 211.808 239.246 220.064 238.193C222.811 237.838 228.188 236.229 233.012 232.357C237.779 228.532 242.021 222.493 242.669 213.154C242.66 204.6 240.331 199.005 238.111 195.603C238.058 195.528 238.018 195.514 237.997 195.508C237.96 195.498 237.896 195.495 237.822 195.523C237.747 195.551 237.701 195.594 237.679 195.627C237.666 195.647 237.641 195.688 237.653 195.793L237.653 195.796C238.243 200.992 237.57 208.567 230.612 213.836C229.295 214.845 227.717 214.585 226.704 213.785C225.699 212.99 225.081 211.535 225.672 210.025L227.069 210.572L225.671 210.027C229.387 200.491 229 192.191 226.233 185.4C223.454 178.582 218.226 173.172 212.086 169.552L212.078 169.548L212.071 169.543C212.003 169.502 211.957 169.498 211.924 169.5C211.883 169.503 211.832 169.519 211.783 169.555C211.734 169.591 211.7 169.636 211.683 169.68C211.669 169.716 211.657 169.771 211.677 169.859C212.915 175.296 212.588 179.563 211.199 183.12C209.818 186.654 207.445 189.335 204.872 191.694C203.698 192.77 202.457 193.801 201.24 194.812C201.126 194.906 201.013 195 200.9 195.095C199.563 196.206 198.257 197.304 197.026 198.472C194.572 200.801 192.481 203.356 191.213 206.651ZM222.753 222.317L225.674 223.027L223.35 224.934C217.868 229.434 212.264 229.793 207.999 227.486C203.836 225.234 201.227 220.573 201.375 215.604C201.387 211.322 204.477 208.087 207.118 205.428C207.315 205.23 207.511 205.034 207.705 204.84C208.884 203.659 209.986 202.555 210.901 201.406C211.957 200.08 212.652 198.823 212.88 197.53L213.818 192.215L215.757 197.252C217.433 201.603 217.013 205.146 216.378 208.132C216.252 208.725 216.126 209.264 216.006 209.773C215.814 210.593 215.64 211.337 215.511 212.114C215.314 213.305 215.275 214.337 215.503 215.326L215.505 215.335L215.507 215.345C216.099 218.069 218.307 221.236 222.753 222.317Z"
                strokeWidth="3"
            />
            <path
                fill={isLight ? theme.white : theme.black}
                d="M180.5 201L163.5 183.5L161 210L149.5 223.5L161 233L184.5 229.5L180.5 201Z"
            />
            <path
                fill={isLight ? theme.white : theme.black}
                stroke={isLight ? theme.colors.orange[6] : theme.colors.orange[3]}
                d="M198.992 198.59L199 198.61L199.008 198.63C204.434 211.612 200.761 226.568 190.653 235.951C181.497 244.329 168.66 246.588 156.611 245.052C152.538 244.525 144.652 242.158 137.577 236.481C130.559 230.849 124.323 221.954 123.379 208.246C123.388 195.742 126.794 187.524 130.076 182.5C130.666 181.64 132.078 182.137 131.953 183.221L131.952 183.224C131.113 190.621 132.083 201.27 141.866 208.678C143.487 209.921 145.428 209.61 146.692 208.611C147.949 207.617 148.707 205.815 147.976 203.946L146.579 204.493L147.977 203.948C142.552 190.027 143.102 177.86 147.169 167.881C151.248 157.876 158.91 149.958 167.882 144.668L167.89 144.663L167.897 144.659C168.669 144.192 169.682 144.865 169.462 145.833C167.697 153.584 168.174 159.607 170.123 164.597C172.064 169.564 175.406 173.355 179.091 176.733C180.774 178.276 182.554 179.754 184.314 181.217C184.478 181.353 184.642 181.488 184.805 181.624C186.735 183.229 188.638 184.827 190.433 186.531C194.016 189.933 197.112 193.703 198.992 198.59ZM152.971 222.106L150.05 222.816L152.373 224.723C160.119 231.079 167.923 231.521 173.806 228.338C179.589 225.21 183.236 218.716 183.025 211.772C183.013 205.898 178.776 201.41 174.924 197.533C174.641 197.249 174.36 196.967 174.081 196.688C172.38 194.985 170.757 193.361 169.411 191.669C167.852 189.711 166.774 187.793 166.417 185.77L165.479 180.455L163.54 185.492C161.186 191.607 161.77 196.578 162.674 200.83C162.849 201.655 163.028 202.422 163.2 203.155C163.481 204.358 163.742 205.472 163.931 206.611C164.223 208.376 164.291 209.963 163.934 211.51L163.932 211.519L163.93 211.528C163.026 215.688 159.664 220.479 152.971 222.106Z"
                strokeWidth="3"
            />
            <path fill={isLight ? theme.white : theme.black} d="M125.5 201L108.5 183.5L106 210L94.5 223.5L106 233L129.5 229.5L125.5 201Z" />
            <path
                fill={isLight ? theme.white : theme.black}
                stroke={isLight ? theme.colors.orange[6] : theme.colors.orange[3]}
                d="M135.956 206.651L135.964 206.671L135.972 206.691C139.649 215.491 137.163 225.641 130.3 232.012C124.094 237.69 115.361 239.246 107.105 238.193C104.358 237.838 98.981 236.229 94.1568 232.357C89.3899 228.532 85.1475 222.493 84.5 213.154C84.5092 204.6 86.8376 199.005 89.0583 195.603C89.1109 195.528 89.1511 195.514 89.1722 195.508C89.2092 195.498 89.2726 195.495 89.3473 195.523C89.4217 195.551 89.4684 195.594 89.4903 195.627C89.5034 195.647 89.5278 195.688 89.5158 195.793L89.5155 195.796C88.9258 200.992 89.5989 208.567 96.5573 213.836C97.874 214.845 99.4523 214.585 100.464 213.785C101.47 212.99 102.088 211.535 101.497 210.025L100.1 210.572L101.498 210.027C97.782 200.491 98.1685 192.191 100.936 185.4C103.715 178.582 108.943 173.172 115.083 169.552L115.091 169.548L115.098 169.543C115.165 169.502 115.212 169.498 115.245 169.5C115.286 169.503 115.337 169.519 115.386 169.555C115.435 169.591 115.468 169.636 115.486 169.68C115.5 169.716 115.512 169.771 115.492 169.859C114.254 175.296 114.581 179.563 115.97 183.12C117.351 186.654 119.724 189.335 122.297 191.694C123.471 192.77 124.712 193.801 125.929 194.812C126.042 194.906 126.156 195 126.269 195.095C127.606 196.206 128.912 197.304 130.143 198.472C132.597 200.801 134.688 203.356 135.956 206.651ZM104.416 222.317L101.495 223.027L103.819 224.934C109.301 229.434 114.905 229.793 119.17 227.486C123.333 225.234 125.942 220.573 125.794 215.604C125.782 211.322 122.692 208.087 120.051 205.428C119.854 205.23 119.658 205.034 119.464 204.84C118.285 203.659 117.183 202.555 116.268 201.406C115.212 200.08 114.517 198.823 114.289 197.53L113.351 192.215L111.412 197.252C109.736 201.603 110.156 205.146 110.791 208.132C110.917 208.725 111.043 209.264 111.163 209.773C111.355 210.593 111.529 211.337 111.657 212.114C111.855 213.305 111.894 214.337 111.666 215.326L111.664 215.335L111.662 215.345C111.069 218.069 108.862 221.236 104.416 222.317Z"
                strokeWidth="3"
            />
            <circle
                fill={isLight ? theme.white : theme.black}
                stroke={isLight ? theme.colors.red[5] : theme.colors.red[4]}
                cx="52.5"
                cy="53"
                r="7"
                strokeWidth="2"
            />
            <circle
                fill={isLight ? theme.white : theme.black}
                stroke={isLight ? theme.colors.red[5] : theme.colors.red[4]}
                cx="282.5"
                cy="202"
                r="7"
                strokeWidth="2"
            />
            <circle fill="none" stroke={isLight ? theme.colors.red[5] : theme.colors.red[4]} cx="232.5" cy="50" r="7" strokeWidth="2" />
            <path
                fill={isLight ? theme.colors.red[8] : theme.colors.red[1]}
                d="M320.5 166H315.5V171C315.5 171.55 315.05 172 314.5 172C313.95 172 313.5 171.55 313.5 171V166H308.5C307.95 166 307.5 165.55 307.5 165C307.5 164.45 307.95 164 308.5 164H313.5V159C313.5 158.45 313.95 158 314.5 158C315.05 158 315.5 158.45 315.5 159V164H320.5C321.05 164 321.5 164.45 321.5 165C321.5 165.55 321.05 166 320.5 166Z"
            />
            <path
                fill={isLight ? theme.colors.red[8] : theme.colors.red[1]}
                d="M129.5 13H124.5V18C124.5 18.55 124.05 19 123.5 19C122.95 19 122.5 18.55 122.5 18V13H117.5C116.95 13 116.5 12.55 116.5 12C116.5 11.45 116.95 11 117.5 11H122.5V6C122.5 5.45 122.95 5 123.5 5C124.05 5 124.5 5.45 124.5 6V11H129.5C130.05 11 130.5 11.45 130.5 12C130.5 12.55 130.05 13 129.5 13Z"
            />
            <path
                fill={isLight ? theme.colors.red[8] : theme.colors.red[1]}
                d="M66.5 191H61.5V196C61.5 196.55 61.05 197 60.5 197C59.95 197 59.5 196.55 59.5 196V191H54.5C53.95 191 53.5 190.55 53.5 190C53.5 189.45 53.95 189 54.5 189H59.5V184C59.5 183.45 59.95 183 60.5 183C61.05 183 61.5 183.45 61.5 184V189H66.5C67.05 189 67.5 189.45 67.5 190C67.5 190.55 67.05 191 66.5 191Z"
            />
        </svg>
    );
};
