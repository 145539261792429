import { ErrorPage } from "modules/Core/error/ErrorPage";
import { NotFoundPage } from "modules/Core/error/NotFoundPage";
import { useEffect } from "react";
import { createSearchParams, useNavigate, useRouteError } from "react-router-dom";
import { useUserStore } from "shared/stores/userStore";
import { HttpError, HttpStatusCode } from "shared/types/Http";
import { getModuleIngresses } from "shared/utils/module";
import { match } from "ts-pattern";

export const ErrorBoundary = () => {
    const error = useRouteError() as HttpError;
    const { resetStore } = useUserStore.getState();
    const coreRoutes = getModuleIngresses("core");
    const navigate = useNavigate();

    useEffect(() => {
        if (error.status && [HttpStatusCode.FORBIDDEN, HttpStatusCode.UNAUTHORIZED].includes(error.status)) {
            resetStore();
            navigate({ pathname: coreRoutes.login(), search: createSearchParams({ from: location.pathname }).toString() });
        }
    }, [error.status]);

    return match(error.status)
        .with(HttpStatusCode.NOT_FOUND, () => <NotFoundPage />)
        .otherwise(() => <ErrorPage error={error} />);
};
