import { useQuery } from "@tanstack/react-query";
import { httpGet } from "shared/api/httpClient";
import { AbstractedQueryOptions } from "shared/types/HttpApi";
import { Organization } from "shared/types/Organization";
import { queryKeyFactory } from "shared/utils/api";

export const useOrganization = (id: string, options?: AbstractedQueryOptions<Organization>) => {
    return useQuery({
        queryKey: organizationKeys.detail(id),
        queryFn: ({ signal }) => httpGet(`/rms/organizations/${encodeURIComponent(id)}`, { signal }),
        meta: {
            errorMessage: "Failed to get organization data",
        },
        ...options,
    });
};

export const organizationKeys = queryKeyFactory("organization");
