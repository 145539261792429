import { datadogRum } from "@datadog/browser-rum";
import { isSome } from "@tsly/maybe";
import { HTMLAttributes, useEffect } from "react";
import { useUserStore } from "shared/stores/userStore";

export const DatadogProvider = (props: HTMLAttributes<HTMLDivElement>) => {
    const givenName = useUserStore((s) => s.user.givenName);
    const surname = useUserStore((s) => s.user.surname);
    const orgId = useUserStore((s) => s.currentOrganization.id);

    useEffect(() => {
        if (import.meta.env.VITE_GIT_FINGERPRINT == "") return; // disable telemetry for localhost dev versions

        datadogRum.init({
            applicationId: "83af9f08-a8b4-4b34-a390-cf7e5128e36d",
            clientToken: "pub191d27a952ed3d5a51067eaff0000433",
            site: "datadoghq.com",
            service: "rms",
            env: import.meta.env.VITE_ENVIRONMENT,
            version: `${import.meta.env.VITE_ENVIRONMENT}-${import.meta.env.VITE_GIT_FINGERPRINT}`,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            /**
             * From Datadog
             *
             * Note: The `trackUserInteractions` parameter enables the automatic collection of user clicks in your application.
             * Sensitive and private data contained in your pages may be included to identify the elements interacted with.
             *
             * Given that and given that personnel contains SSNs we really never want to expose this information to a 3rd party service.
             */
            trackUserInteractions: false,
            trackResources: true,
            trackLongTasks: true,
            silentMultipleInit: true,
            defaultPrivacyLevel: "mask-user-input",
        });

        if (isSome(givenName) && isSome(surname)) datadogRum.setUser({ name: `${givenName} ${surname}` });
        if (isSome(orgId)) datadogRum.setGlobalContextProperty("orgId", orgId);
    }, []);

    return <div {...props} />;
};
