import { mapToZodLiteralUnion } from "shared/utils/zod";
import { z } from "zod";

export const documentTypeLiterals = ["image/jpeg", "image/png", "image/bmp", "image/gif", "application/pdf"];

export const documentSchema = z.object({
    id: z.string(),
    name: z.string(),
    contentType: mapToZodLiteralUnion(["image/jpeg", "image/png", "image/bmp", "image/gif", "application/pdf"]),
    size: z.number(),
    width: z.number().nullish(),
    height: z.number().nullish(),
    preSignedUrl: z.string(),
});

export type Document = z.infer<typeof documentSchema>;
export type PresignedUrl = string;
