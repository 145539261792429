import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Personnel Types",
    slug: "personnel-type",
    resource: "personnelType",
    queryKeys: {
        tableData: "personnelTypeTableData",
    },
    ingresses: {
        base: () => "" as const,
        new: () => "/new" as const,
        edit: (personnelTypeId: string) => `/${personnelTypeId}` as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["personnelType"],
        hiddenUnlessNested: true,
    },
    routes: [
        <Route
            key="personnelTypeTable"
            index
            Component={lazy(() =>
                import("modules/PersonnelType/PersonnelTypeTable").then((module) => ({ default: module.PersonnelTypeTable })),
            )}
        />,
        <Route
            key="personnelTypeDetail"
            path=":typeId"
            Component={lazy(() =>
                import("modules/PersonnelType/PersonnelTypeBuilder/PersonnelTypeBuilder").then((module) => ({
                    default: module.PersonnelTypeBuilder,
                })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
