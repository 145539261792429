import { useMutation } from "@tanstack/react-query";
import { httpDelete, httpPost, httpPut } from "shared/api/httpClient";
import { createToast } from "shared/notifications/createToast";
import { useUserStore } from "shared/stores/userStore";
import { AbstractedMutationOptions } from "shared/types/HttpApi";
import { UserAccount } from "shared/types/UserAccount";
import { logger } from "shared/utils/logger";
import { getDeviceName } from "shared/utils/platformInfo";

export const useSignIn = ({ onSuccess, onError, ...rest }: AbstractedMutationOptions<UserAccount, { email: string; password: string }>) => {
    const { mutate: signIn, ...mut } = useMutation({
        throwOnError: false,
        mutationFn: ({ email, password }) => {
            const deviceId = useUserStore.getState().session.deviceId;
            const authBody = {
                credentials: {
                    method: "email",
                    email,
                    password,
                },
                hardwareId: deviceId,
                hardwareInfo: { deviceType: import.meta.env.VITE_SITE },
                serviceVersion: import.meta.env.VITE_VERSION,
                deviceName: getDeviceName(import.meta.env.VITE_SITE),
            };

            return httpPost(`/rms/session`, authBody);
        },
        onSuccess: (res, ...rest) => {
            onSuccess?.(res, ...rest);
        },
        onError: (err, ...rest) => {
            onError?.(err, ...rest);
            createToast({ variant: "error", title: "Error", children: "Failed to sign in" });
            logger.error(err);
        },
        ...rest,
    });

    return {
        signIn,
        ...mut,
    };
};

export const useForgotPassword = ({
    onSuccess,
    onError,
    ...rest
}: AbstractedMutationOptions<unknown, { email: string; response: string }>) => {
    const { mutate: forgotPassword, ...mut } = useMutation({
        throwOnError: false,
        mutationFn: ({ email }) => {
            return httpPost(`/password-reset`, { email, response: "" });
        },
        onSuccess: (res, ...rest) => {
            onSuccess?.(res, ...rest);
        },
        onError: (err, ...rest) => {
            onError?.(err, ...rest);
            createToast({ variant: "error", title: "Error", children: "Failed to initiate password reset" });
            logger.error(err);
        },
        ...rest,
    });

    return {
        forgotPassword,
        ...mut,
    };
};

export const useResetPassword = ({
    onSuccess,
    onError,
    ...rest
}: AbstractedMutationOptions<unknown, { token: string; newPassword: string }>) => {
    const { mutate: resetPassword, ...mut } = useMutation({
        throwOnError: false,
        mutationFn: ({ token, newPassword }) => {
            return httpPut(`/password-reset/${encodeURIComponent(token)}`, { newPassword });
        },
        onSuccess: (res, ...rest) => {
            onSuccess?.(res, ...rest);
        },
        onError: (err, ...rest) => {
            onError?.(err, ...rest);
            createToast({ variant: "error", title: "Error", children: "Failed to reset password" });
            logger.error(err);
        },
        ...rest,
    });

    return {
        resetPassword,
        ...mut,
    };
};

export const useSignOut = ({ onSuccess, onError, ...rest }: AbstractedMutationOptions<unknown, void>) => {
    const { mutate: signOut, ...mut } = useMutation({
        throwOnError: false,
        mutationFn: () => {
            return httpDelete("/rms/session");
        },
        onSuccess: (res, ...rest) => {
            onSuccess?.(res, ...rest);
        },
        onError: (err, ...rest) => {
            onError?.(err, ...rest);
            createToast({ variant: "error", title: "Error", children: "Failed to sign out" });
            logger.error(err);
        },
        ...rest,
    });

    return {
        signOut,
        ...mut,
    };
};
