import { useBwsTopic } from "shared/bws/core";
import { createToast } from "shared/notifications/createToast";

export const RefreshNotificationProvider = (props: { children: React.ReactNode }) => {
    useBwsTopic("versions/rms", {
        onServerUpdate: () => {
            createToast({
                variant: "info",
                title: `A new version is available!`,
                children: "Please save your work and reload",
                options: {
                    duration: Infinity,
                },
            });
        },
    });

    return <>{props.children}</>;
};
