import { Text } from "@mantine/core";
import { IconChartPie, IconChartPieFilled } from "@tabler/icons-react";
import { withSessionGuard } from "modules/Core/router/guards";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Dashboard",
    slug: "dashboard",
    icon: {
        default: IconChartPie,
        filled: IconChartPieFilled,
    },
    queryKeys: {},
    ingresses: {
        base: () => `/`,
    },
    routes: [<Route key="index" index element={withSessionGuard(<Text>Dashboard stub; check back later. RMS-1212</Text>)} />],
    sidebar: {
        hiddenUnlessNested: true,
    },
} as const satisfies ModuleSpec;
