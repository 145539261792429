import { obj } from "@tsly/obj";
import { getModuleSpec, RegisteredModule, registeredModuleSpecs, RegisteredModuleWithName } from "modules/Core/registrar";
import { useLocation, useNavigate } from "react-router-dom";

type MappedModuleIngresses<T extends RegisteredModule["name"], TIngresses = RegisteredModuleWithName<T>["ingresses"]> = {
    readonly [k in keyof TIngresses]: (
        ...args: TIngresses[k] extends (...args: infer Args) => string ? Args : []
    ) => TIngresses[k] extends ((...args: string[]) => infer R extends string) ? `/${RegisteredModuleWithName<T>["slug"]}${R}` : never;
};

export function getModuleIngresses<TModuleName extends RegisteredModule["name"]>(name: TModuleName): MappedModuleIngresses<TModuleName> {
    const spec = getModuleSpec(name);

    function prependSlug<T extends string[], TFn extends (...args: T) => string>(fn: TFn) {
        return (...args: T) => `/${spec.slug}${fn(...args)}`;
    }

    return obj(Object.fromEntries(Object.entries(spec.ingresses).map(([k, fn]) => [k, prependSlug(fn)]))).cast();
}

type MappedModuleNavHooks<
    T extends RegisteredModule["name"],
    TIngresses = RegisteredModuleWithName<T>["ingresses"],
    TMapped = MappedModuleIngresses<T>,
> = {
    readonly [k in keyof TIngresses]: k extends keyof TMapped
        ? TMapped[k] & { nav: (...args: TMapped[k] extends (...args: infer Args) => unknown ? Args : never) => void }
        : never;
};

export function useModuleNav<TModuleName extends RegisteredModule["name"]>(name: TModuleName): MappedModuleNavHooks<TModuleName> {
    const nav = useNavigate();
    const routes = getModuleIngresses(name);
    const ret = { ...routes } as MappedModuleNavHooks<TModuleName>;

    for (const k of obj(routes).keys) {
        const fn = ret[k];
        fn.nav = ((...args: typeof fn extends (...args: infer Args) => string ? Args : never) => nav(fn(...args))) as (typeof fn)["nav"];
    }

    return ret;
}

export function useActiveModuleSpec(): RegisteredModule | null {
    const loc = useLocation();

    return registeredModuleSpecs.find((spec) => spec.slug == loc.pathname.split("/")[1]) ?? null;
}
