import { useQuery } from "@tanstack/react-query";
import { httpGet } from "shared/api/httpClient";
import { AbstractedQueryOptions } from "shared/types/HttpApi";
import { UserAuthority } from "shared/types/UserAccount";
import { queryKeyFactory } from "shared/utils/api";

export const useAccountPermissions = (accountId: string, options?: AbstractedQueryOptions<UserAuthority>) => {
    return useQuery({
        queryKey: accountKeys.detail(accountId),
        queryFn: ({ signal }) => httpGet(`/rms/accounts/${encodeURIComponent(accountId)}/permissions`, { signal }),
        meta: {
            errorMessage: "Failed to get account permissions",
        },
        ...options,
    });
};

const accountKeys = queryKeyFactory("accounts");
