import { DeepUnwind } from "@tsly/core";
import { useEffect } from "react";
import { bwsMessageType } from "shared/bws/messageType";
import { bwsStore } from "shared/bws/store";
import { bwsRmsTopic } from "shared/bws/topics/rms";
import { bwsRmsMapTopic } from "shared/bws/topics/rmsMap";
import { bwsServiceVersionTopic } from "shared/bws/topics/serviceVersion";
import { useUserStore } from "shared/stores/userStore";
import { z } from "zod";

export const registeredTopics = [bwsServiceVersionTopic, bwsRmsTopic, bwsRmsMapTopic] as const;
export type RegisteredTopic = DeepUnwind<typeof registeredTopics>;

type RegisteredTopicAtKey<TTopicKey extends z.infer<RegisteredTopic["key"]>> = {
    [k in RegisteredTopic["name"]]: TTopicKey extends z.infer<Extract<RegisteredTopic, { name: k }>["key"]>
        ? Extract<RegisteredTopic, { name: k }>
        : never;
}[RegisteredTopic["name"]];

type BwsTopicSubscription<TTopicKey extends z.infer<RegisteredTopic["key"]>> = {
    /**
     * The key representing which {@link RegisteredTopic} this subscription is bound to
     */
    key: TTopicKey;
    /**
     * A boolean flag representing if this subscription has had it's initial data sent to it.
     * Typically, this data is cached in a {@link BwsInitialDataCache} the the initial data is sent on component mount,
     * however if no other topic subscriptions activly exist then the emission of initial data will be defered until the
     * server responds with a {@link bwsMessageType.subscribeResponse subscribe response} containing the initial data.
     */
    initialDataEmitted: boolean;
    /**
     * The unique identifier for each {@link BwsTopicSubscription}
     */
    subscriptionId: number;
    /**
     * The callback that will be fired when any {@link bwsMessageType.serverUpdate server update} messages are recivied associated with this
     * topic key
     */
    onServerUpdate?: (payload: z.infer<RegisteredTopicAtKey<TTopicKey>["serverUpdate"]>) => void;
    /**
     * The callback that will be fired after the subscription is registered containing the [initial data](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#accepted-requests) contained in the {@link bwsMessageType.subscribeResponse subscription response}.
     */
    onInitialData?: (initialData: z.infer<RegisteredTopicAtKey<TTopicKey>["initalData"]>) => void;
};

export type BwsSubscriptionLedger = {
    [k in z.infer<RegisteredTopic["key"]>]?: BwsTopicSubscription<k>[];
};

export type BwsInitialDataCache = {
    [k in z.infer<RegisteredTopic["key"]>]?: RegisteredTopicAtKey<k>["initalData"];
};

export type UseBwsTopicOpts<TTopicKey extends z.infer<RegisteredTopic["key"]>> = {
    onServerUpdate?: BwsTopicSubscription<TTopicKey>["onServerUpdate"];
    onInitialData?: BwsTopicSubscription<TTopicKey>["onInitialData"];
    deps?: React.DependencyList;
} & (RegisteredTopicAtKey<TTopicKey> extends {
    params: infer TParams extends z.AnyZodObject;
}
    ? { params: z.infer<TParams> }
    : object);

type UseBwsTopicReturn<TTopicKey extends z.infer<RegisteredTopic["key"]>> = {
    /**
     * Force the underlying websocket to connection to resend the subscribe request with an updated params object.
     *
     * ?> This affect all current subscriptions with the same key.
     */
    forceResubscribe: RegisteredTopicAtKey<TTopicKey> extends { params: infer TParams extends z.SomeZodObject }
        ? (params: z.infer<TParams>) => void
        : () => void;
    /**
     * Send a topic-specific message to the server
     *
     * See [Sending Client Updates](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#sending-client-updates)
     * @param data
     */
    sendUpdate: RegisteredTopicAtKey<TTopicKey> extends { clientUpdate: infer TClientUpdate extends z.SomeZodObject }
        ? (update: z.infer<TClientUpdate>) => void
        : never;
};

/**
 * Registers a Bryx WebSocket topic subscription and binds it to the lifetime of the hooked component, registering on mount
 * and cleaning up on unmount
 * 
 *
 * @param {TTopicKey} key The key of the BWS {@link RegisteredTopic} to subscribe to.
 * @param {UseBwsTopicOpts<TTopicKey>} opts Options for the subscription.
 * @returns {UseBwsTopicReturn<TTopicKey>} An object containing functions and utilities for interacting with the allocated {@link BwsTopicSubscription}
 
 * @example
 * ```tsx
 * const VersionReadout = () => {
 *   const [ initalData, setInitialData ] = useState("");
 *   const [ data, pushData ] = useReducer(
 *     (arr: string[], el: string) => arr.concat([el]), 
 *     []
 *   );
 * 
 *   // this subscription will close when this component unmounts
 *   const { sendUpdate } = useBwsTopic("some/topic", {
 *     // fires on new data
 *     onServerUpdate: (data) => pushData(data), 
 *     // fires with initial data on subscribe response, or right away if a BWS topic
 *     // subscription for this topic is already active
 *     onInitialData: (data) => setInitialData(data)
 *   });
 * 
 *   return (
 *     <div>
 *       <pre>{JSON.stringify({ data, initialData })}</pre>
 *       <button onClick={() => sendUpdate("hello!")}>Send Update</button>
 *     </div>
 *   )
 * }
 * ```
 */
export function useBwsTopic<TTopicKey extends z.infer<RegisteredTopic["key"]>>(
    key: TTopicKey,
    opts: UseBwsTopicOpts<TTopicKey>,
): UseBwsTopicReturn<TTopicKey> {
    const { connection, startConnection, openSubscription, closeSubscription } = bwsStore.getState();
    const session = useUserStore((s) => s.session);

    if (connection?.readyState != WebSocket.OPEN && session.apiKey != "") startConnection();

    useEffect(() => {
        const subId = openSubscription(key, opts);

        return () => {
            closeSubscription(subId);
        };
    }, opts.deps);

    return {
        forceResubscribe: (params) => {
            const id = bwsStore.getState().messageCounter;
            void bwsStore.getState().sendMessage(
                JSON.stringify({
                    type: bwsMessageType.subscribeRequest,
                    id,
                    topic: key,
                    params,
                }),
            );

            bwsStore.setState({ messageCounter: id + 1 });
        },
        sendUpdate: (data) => {
            const id = bwsStore.getState().messageCounter;

            void bwsStore.getState().sendMessage(
                JSON.stringify({
                    type: bwsMessageType.clientUpdate,
                    id,
                    topic: key,
                    data,
                }),
            );
        },
    } as UseBwsTopicReturn<TTopicKey>;
}
