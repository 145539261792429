import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Hydrant Settings",
    slug: "hydrant-settings",
    queryKeys: {},
    ingresses: {
        base: () => `/`,
    },
    routes: [
        <Route
            key="hydrantSettings"
            index
            Component={lazy(() =>
                import("modules/HydrantSettings/HydrantSettings").then((module) => ({ default: module.HydrantSettings })),
            )}
        />,
    ],
    sidebar: {
        hiddenUnlessNested: true,
        visibilityRequiresReadAccessToAnyOf: ["organization"],
    },
} as const satisfies ModuleSpec;
