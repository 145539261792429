import { Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { ErrorNavigation } from "modules/Core/error/ErrorNavigation";
import { ErrorWrapper } from "modules/Core/error/ErrorWrapper";
import { ErrorSvg } from "shared/components/svg/ErrorSvg";
import { HttpError } from "shared/types/Http";

export const ErrorPage = ({ error }: { error: HttpError }) => {
    const theme = useMantineTheme();

    return (
        <ErrorWrapper>
            <ErrorSvg />
            <Stack align="center" gap={theme.spacing["3xl"]}>
                <Stack align="center" gap="lg">
                    <Title c="var(--mantine-color-primary-color)" order={1}>
                        Error {error.status}
                    </Title>
                    <Text>Looks like there is a fire on this page.</Text>
                </Stack>
                <ErrorNavigation />
            </Stack>
        </ErrorWrapper>
    );
};
