import { pointSchema } from "shared/types/geojson";
import { mapToZodLiteralUnion, nonemptyString } from "shared/utils/zod";
import { match } from "ts-pattern";
import { z } from "zod";

export const addressSchema = z.object({
    streetAddress: nonemptyString(),
    city: nonemptyString(),
    state: nonemptyString(),
    zip: nonemptyString(),
    streetAddress2: z.string().optional(),
    aptNumber: z.string().optional(),
    geoPoint: pointSchema,
    fullAddress: nonemptyString(),
});

export const optionalAddressSchema = z
    .object({
        streetAddress: z.string().nullish(),
        city: z.string().nullish(),
        state: z.string().nullish(),
        zip: z.string().nullish(),
        aptNumber: z.string().nullish(),
        geoPoint: pointSchema.nullish(),
    })
    .optional()
    .nullable()
    .superRefine((data, ctx) => {
        if (!data) return;

        const fields = ["streetAddress", "city", "state", "zip", "aptNumber"] as const;
        const requiredFields = ["streetAddress", "city", "state", "zip"] as const;
        const filledFields = fields.filter((field) => data[field] !== null && data[field] !== "" && data[field] !== undefined);
        const emptyFields = requiredFields.filter((field) => data[field] === null || data[field] === "" || data[field] === undefined);

        if (filledFields.length > 0 && emptyFields.length > 0) {
            emptyFields.forEach((requiredFields) => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [requiredFields],
                    message: match(requiredFields)
                        .with(
                            "streetAddress",
                            () =>
                                "Street Address required. All address fields must be completed if 2nd Address, City, State, or Zip specified.",
                        )
                        .with(
                            "city",
                            () =>
                                "City is required. All address fields must be completed if Street Address, 2nd Address, State, or Zip specified.",
                        )
                        .with(
                            "state",
                            () =>
                                "State is required. All address fields must be completed if Street Address, 2nd Address, City, or Zip specified.",
                        )
                        .with(
                            "zip",
                            () =>
                                "Zip is required. All address fields must be completed if Street Address, 2nd Address, State, or City specified.",
                        )
                        .exhaustive(),
                });
            });
        }
    })
    .transform((data) => {
        if (!!data) {
            const requiredFields = [data.streetAddress, data.city, data.state, data.zip];
            const allEmpty = requiredFields.every((field) => field == undefined || field == "");
            return allEmpty ? undefined : data;
        }
        return data;
    });

export const contactSchema = z.object({
    name: nonemptyString(),
    jobTitle: z.string().optional(),
    description: z.string().optional(),
    phone: z.string().optional(),
    email: z.string().optional(),
    type: mapToZodLiteralUnion(["primary", "secondary", "other", "inactive"]),
    comments: z.string().optional(),
});
