import { IconUser, IconUserFilled } from "@tabler/icons-react";
import PersonnelCompanySpec from "modules/PersonnelCompany/_spec";
import PersonnelRankSpec from "modules/PersonnelRank/_spec";
import PersonnelServicePositionSpec from "modules/PersonnelServicePosition/_spec";
import PersonnelStatusSpec from "modules/PersonnelStatus/_spec";
import PersonnelTeamsSpec from "modules/PersonnelTeams/_spec";
import PersonnelTypeSpec from "modules/PersonnelType/_spec";
import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Personnel",
    slug: "personnel",
    resource: "personnel",
    icon: {
        default: IconUser,
        filled: IconUserFilled,
    },
    queryKeys: {
        tableData: "personnelTableData",
        historyTableData: "historyTableData",
    },
    nestedModules: [
        PersonnelCompanySpec,
        PersonnelServicePositionSpec,
        PersonnelStatusSpec,
        PersonnelTeamsSpec,
        PersonnelTypeSpec,
        PersonnelRankSpec,
    ],
    ingresses: {
        base: () => "" as const,
        new: () => "/new" as const,
        edit: (personnelId: string) => `/${personnelId}` as const,
        print: (personnelId: string) => `/${personnelId}/print` as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["personnel"],
    },
    routes: [
        <Route
            key="personnelTable"
            index
            Component={lazy(() => import("modules/Personnel/PersonnelTable").then((module) => ({ default: module.PersonnelTable })))}
        />,
        <Route
            key="personnelBuilder"
            path=":personnelId"
            Component={lazy(() =>
                import("modules/Personnel/PersonnelBuilder/PersonnelBuilder").then((module) => ({ default: module.PersonnelBuilder })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
