import { useEffect, useState } from "react";
import { AccessPermission, AccessRolePermissions, UserOrganization } from "shared/types/AccessRole";
import { Centroid, pointSchema } from "shared/types/geojson";
import { LocationData, OrganizationNfirsConfig } from "shared/types/Organization";
import { RmsSettings, UserAuthority } from "shared/types/UserAccount";
import { z } from "zod";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type UserStore = {
    session: {
        apiKey: string;
        deviceId: string;
    };
    userAuthority: UserAuthority;
    user: {
        id: string;
        givenName: string;
        surname: string;
        organizations: UserOrganization[];
        rmsUserSettings: RmsSettings;
    };
    currentOrganization: {
        id: string;
        name: string;
        nfirsConfig: OrganizationNfirsConfig;
        locationData: LocationData;
    };
    defaultOrganization: {
        id: string;
        name: string;
        nfirsConfig: OrganizationNfirsConfig;
        locationData: LocationData;
    };
    setSession: (args: { apiKey: string; deviceId: string }) => void;
    setUserAuthority: (args: UserAuthority) => void;
    setUser: (args: {
        id: string;
        givenName: string;
        surname: string;
        organizations: UserOrganization[];
        rmsUserSettings: RmsSettings;
    }) => void;
    setCurrentOrganization: (args: { id: string; name: string; nfirsConfig: OrganizationNfirsConfig; locationData: LocationData }) => void;
    setDefaultOrganization: (args: { id: string; name: string; nfirsConfig: OrganizationNfirsConfig; locationData: LocationData }) => void;
    resetStore: () => void;
};

const userStoreDefault = {
    session: {
        apiKey: "",
        deviceId: "",
    },
    userAuthority: {
        id: "",
        organizationId: "",
        accountId: "",
        administrator: false,
        accessRoles: [],
    },
    user: {
        id: "",
        givenName: "",
        surname: "",
        organizations: [],
        rmsUserSettings: {
            colorMode: "auto" as const,
        },
    },
    currentOrganization: {
        id: "",
        name: "",
        nfirsConfig: {} as OrganizationNfirsConfig,
        locationData: {
            centroid: {
                type: "Point",
                coordinates: [1.1, 1.1],
            } satisfies z.infer<typeof pointSchema>,
            boundary: {
                type: "Polygon",
                properties: {},
                coordinates: [[[0, 0]]],
            },
        },
    } satisfies UserStore["currentOrganization"],
    defaultOrganization: {
        id: "",
        name: "",
        nfirsConfig: {} as OrganizationNfirsConfig,

        locationData: {
            centroid: {
                type: "Point",
                coordinates: [1.1, 1.1],
            } satisfies Centroid,
            boundary: {
                type: "Polygon",
                properties: {},
                coordinates: [[[0, 0]]],
            },
        },
    } satisfies UserStore["defaultOrganization"],
};

export const useUserStore = create<UserStore>()(
    devtools(
        // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#options
        persist(
            immer((set) => {
                const resetStore = () => {
                    const defaultOrganization = {
                        id: useUserStore.getState().defaultOrganization.id,
                        name: useUserStore.getState().defaultOrganization.name,
                        locationData: useUserStore.getState().defaultOrganization.locationData,
                    };
                    set(userStoreDefault);
                    useUserStore.persist.clearStorage();
                    set({
                        defaultOrganization: {
                            id: defaultOrganization.id,
                            name: defaultOrganization.name,
                            nfirsConfig: {} as OrganizationNfirsConfig,
                            locationData: defaultOrganization.locationData,
                        },
                    });
                };
                return {
                    ...userStoreDefault,
                    setSession: (session) => set({ session }),
                    setUserAuthority: (userAuthority) => set({ userAuthority }),
                    setUser: (user) =>
                        set((store) => {
                            store.user = { ...store.user, ...user };
                        }),
                    setCurrentOrganization: (currentOrganization) => set({ currentOrganization }),
                    setDefaultOrganization: (defaultOrganization) => set({ defaultOrganization }),
                    resetStore,
                };
            }),
            {
                name: "rms.store.user",
                storage: createJSONStorage(() => localStorage),
                partialize: (store) => ({
                    session: store.session,
                    user: store.user,
                    currentOrganization: {
                        id: store.currentOrganization.id,
                        name: store.currentOrganization.name,
                        locationData: store.currentOrganization.locationData,
                    },
                    defaultOrganization: {
                        id: store.defaultOrganization.id,
                        name: store.defaultOrganization.name,
                        locationData: store.defaultOrganization.locationData,
                    },
                }),
            },
        ),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "User" },
    ),
);

export const useIsSessionValid = () => {
    const apiKey = useUserStore((s) => s.session.apiKey);
    const [isSessionValid, setIsSessionValid] = useState<boolean>(Boolean(apiKey.replace(/\s+/g, "")));

    useEffect(() => {
        setIsSessionValid(Boolean(apiKey.replace(/\s+/g, "")));
    }, [apiKey]);

    return isSessionValid;
};

/**
 * Determines if the current user has access to a module for a requested set of permissions.
 * If the user is an admin as granted by the organization membership, the user is granted access.
 *
 * @param requestedModule module the requested permission(s) belong to
 * @param requestedPermission permission that must be complete
 * @returns boolean
 */
export const useModulePermission = <TAccessModule extends keyof AccessRolePermissions>(
    requestedModule: TAccessModule,
    requestedPermission: AccessRolePermissions[TAccessModule][number],
): boolean => {
    const [hasAccess, setHasAccess] = useState<boolean>(false);

    const userId = useUserStore((s) => s.userAuthority.id);
    const userAccessRoles = useUserStore((s) => s.userAuthority.accessRoles);
    const currentOrganizationId = useUserStore((s) => s.userAuthority.organizationId);
    const isOrganizationAdmin = useUserStore((s) => s.userAuthority.administrator);

    useEffect(() => {
        if (!Boolean(currentOrganizationId)) return setHasAccess(false);
        if (isOrganizationAdmin) return setHasAccess(true);

        // aggregate all of the role permissions for the specified module within the users CURRENT organization
        const permissionList: AccessPermission[] = [];

        for (const userRole of userAccessRoles) {
            const permissions = userRole.permissions;

            if (Object.prototype.hasOwnProperty.call(permissions, requestedModule)) {
                const permissionAttributes = permissions[requestedModule];
                permissionList.push(...permissionAttributes);
            }
        }

        // determine if the entire list of requested permissions exists in the list produced by rolling
        // all of the permissions from all of the roles up
        setHasAccess(permissionList.includes(requestedPermission));
    }, [userId, currentOrganizationId, isOrganizationAdmin, userAccessRoles]);

    return hasAccess;
};

export const useModulePermissionGetter = () => {
    const userAccessRoles = useUserStore((s) => s.userAuthority.accessRoles);
    const currentOrganizationId = useUserStore((s) => s.userAuthority.organizationId);
    const isOrganizationAdmin = useUserStore((s) => s.userAuthority.administrator);

    return <TAccessModule extends keyof AccessRolePermissions>(
        requestedModule: TAccessModule,
        requestedPermissions: AccessRolePermissions[TAccessModule][number][],
    ): boolean => {
        if (!Boolean(currentOrganizationId)) return false;
        if (isOrganizationAdmin) return true;

        // aggregate all of the role permissions for the specified module within the users CURRENT organization
        const permissionList = userAccessRoles.reduce((permissionList, { permissions }) => {
            return permissionList.concat(permissions[requestedModule] ?? []);
        }, [] as AccessPermission[]);

        // determine if the entire list of requested permissions exists in the list produced by rolling
        // all of the permissions from all of the roles up
        return requestedPermissions.every((item) => permissionList.includes(item));
    };
};
