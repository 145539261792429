import { Button, Group, Text } from "@mantine/core";
import { RegisteredModule } from "modules/Core/registrar";
import classes from "modules/Core/sidebar/SidebarNav.module.css";
import { NavLink } from "react-router-dom";
import { match, P } from "ts-pattern";

interface SidebarNavItemProps {
    spec: RegisteredModule;
    isOpen?: boolean;
}

export const SidebarNavItem = ({ spec, isOpen }: SidebarNavItemProps) => {
    const iconSet = match(spec)
        .with({ icon: P._ }, ({ icon }) => ({
            DefaultIcon: icon["default"],
            FilledIcon: icon["filled"],
        }))
        .otherwise(() => null);
    const label = match(spec)
        .with({ sidebar: { displayName: P.string } }, (s) => s.sidebar.displayName)
        .otherwise(() => spec.name);

    return (
        <NavLink to={spec.slug} tabIndex={-1} style={{ textDecoration: "none" }}>
            {({ isActive }) => {
                return (
                    <Button className={classes.item} variant="transparent" data-active={isActive || isOpen}>
                        <Group align="center" wrap="nowrap">
                            {iconSet && (isActive || isOpen ? <iconSet.FilledIcon size="16" /> : <iconSet.DefaultIcon size="16" />)}
                            <Text>{label}</Text>
                        </Group>
                    </Button>
                );
            }}
        </NavLink>
    );
};
