import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Point Systems",
    slug: "point-systems",
    queryKeys: {},
    ingresses: {
        base: () => `/`,
        edit: (id: string) => `/${id}`,
        new: () => `/new`,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["points"],
        hiddenUnlessNested: true,
    },
    routes: [
        <Route
            key="point-systems-index"
            index
            Component={lazy(() => import("modules/PointSystems/PointsManagePage").then((module) => ({ default: module.PointsManagePage })))}
        />,
        <Route
            key="point-system-builder"
            path=":id"
            Component={lazy(() =>
                import("modules/PointSystems/PointSystemBuilder").then((module) => ({ default: module.PointSystemBuilder })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
