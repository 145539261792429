import { IconMapPin, IconMapPinFilled } from "@tabler/icons-react";
import HydrantsSpec from "modules/Hydrant/_spec";
import PropertySpec from "modules/Property/_spec";
import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Map",
    slug: "map",
    icon: {
        default: IconMapPin,
        filled: IconMapPinFilled,
    },
    queryKeys: {},
    nestedModules: [PropertySpec, HydrantsSpec],
    ingresses: {
        base: () => "" as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["hydrants"],
    },
    routes: [
        <Route key="mapIndex" index Component={lazy(() => import("modules/Map/Map").then((module) => ({ default: module.MapView })))} />,
    ],
} as const satisfies ModuleSpec;
