import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    name: "core",
    slug: "-",
    queryKeys: {},
    ingresses: {
        base: () => `/`,
        login: () => `/login`,
        forgotPassword: () => `/password-reset`,
        resetPassword: <TToken extends string>(token: TToken) => `/password-reset/${token}`,
    },
    routes: [
        <Route index element={<Navigate replace to="/" />} key={0} />,
        <Route
            path="login"
            Component={lazy(() => import("modules/Core/login/Login").then((module) => ({ default: module.Login })))}
            key={1}
        />,
        <Route
            path="password-reset"
            Component={lazy(() =>
                import("modules/Core/forgot-password/ForgotPassword").then((module) => ({ default: module.ForgotPassword })),
            )}
            key={2}
        />,
        <Route
            path="password-reset/:token"
            Component={lazy(() =>
                import("modules/Core/reset-password/ResetPassword").then((module) => ({ default: module.ResetPassword })),
            )}
            key={3}
        />,
    ],
    sidebar: {
        hiddenUnlessNested: true,
    },
    devOnly: false,
    noShell: true,
} as const satisfies ModuleSpec;
