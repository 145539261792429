import { err } from "@tsly/core";
import AuditSpec from "modules/Audit/_spec";
import CalendarSpec from "modules/Calendar/_spec";
import CoreSpec from "modules/Core/_spec";
import DashboardSpec from "modules/Dashboard/_spec";
import EventSubTypeSpec from "modules/EventSubTypes/_spec";
import HydrantSpec from "modules/Hydrant/_spec";
import HydrantSettingsSpec from "modules/HydrantSettings/_spec";
import IncidentTypeSpec from "modules/IncidentType/_spec";
import MapSpec from "modules/Map/_spec";
import OrgManagementSpec from "modules/OrgManagement/_spec";
import PersonnelSpec from "modules/Personnel/_spec";
import PersonnelCompanySpec from "modules/PersonnelCompany/_spec";
import PersonnelRank from "modules/PersonnelRank/_spec";
import PersonnelServicePositionSpec from "modules/PersonnelServicePosition/_spec";
import PersonnelStatusSpec from "modules/PersonnelStatus/_spec";
import PersonnelTeamsSpec from "modules/PersonnelTeams/_spec";
import PersonnelTypeSpec from "modules/PersonnelType/_spec";
import PointsSpec from "modules/PointSystems/_spec";
import PropertySpec from "modules/Property/_spec";
import SandboxSpec from "modules/Sandbox/_spec";
import SettingsSpec from "modules/Settings/_spec";
import VendorSpec from "modules/Vendor/_spec";
import { ModuleSpec } from "shared/types/ModuleSpec";

export const registeredModuleSpecs = [
    AuditSpec,
    CoreSpec,
    DashboardSpec,
    CalendarSpec,
    MapSpec,
    SettingsSpec,
    PersonnelSpec,
    HydrantSpec,
    VendorSpec,
    PersonnelRank,
    PersonnelCompanySpec,
    PersonnelServicePositionSpec,
    PersonnelStatusSpec,
    PersonnelTeamsSpec,
    PersonnelTypeSpec,
    PropertySpec,
    OrgManagementSpec,
    SandboxSpec,
    HydrantSettingsSpec,
    EventSubTypeSpec,
    IncidentTypeSpec,
    PointsSpec,
] as const satisfies readonly ModuleSpec[];

export type RegisteredModule = (typeof registeredModuleSpecs)[number];
export type RegisteredModuleWithName<TName extends RegisteredModule["name"]> = Extract<RegisteredModule, { name: TName }>;

export function getModuleSpec<TName extends RegisteredModule["name"]>(name: TName): RegisteredModuleWithName<TName> {
    return (registeredModuleSpecs.find((spec) => spec.name == name) ??
        err(`Module with name '${name}' is not registered.`)) as RegisteredModuleWithName<TName>;
}
