/* eslint-disable no-console */
import { datadogRum } from "@datadog/browser-rum";
import { fromError, ZodError } from "zod-validation-error";

export const logger = {
    warnZod: (error: ZodError, ...optionalParams: unknown[]) => {
        logger.warn(fromError(error).toString(), ...optionalParams);
    },
    log: (message: unknown, ...optionalParams: unknown[]) => {
        console.log(message, ...optionalParams);
    },

    warn: (message: unknown, ...optionalParams: unknown[]) => {
        console.warn(message, ...optionalParams);
    },

    error: (error: Error, context?: Record<string, unknown>) => {
        console.error(error);
        datadogRum.addError(error, context);
    },

    debug: (message: unknown, ...optionalParams: unknown[]) => {
        console.debug(message, ...optionalParams);
    },

    alert: (message: unknown) => {
        window.alert(message);
    },
};
