import { BwsTopic } from "shared/bws/topic";
import { z } from "zod";

// note: this is a stub topic definition for when the `RMS` topic gets finalized/merged :)

export const bwsRmsTopic = {
    name: "rms",
    params: z.object({ name: z.string() }),
    key: z.literal("rms"),
    initalData: z.object({}),
    clientUpdate: z.object({ name: z.string() }),
    serverUpdate: z.object({}),
} as const satisfies BwsTopic;
