import { AppShell, Avatar, Box, Combobox, Group, Input, InputBase, Menu, ScrollArea, Stack, Text, useCombobox } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useConstCallback } from "@tsly/react";
import logo from "modules/Core/sidebar/logo.png";
import { SidebarLinkGroup } from "modules/Core/sidebar/SidebarLinkGroup";
import { useSignOut } from "shared/api/authApi";
import { useUserStore } from "shared/stores/userStore";
import { OrganizationNfirsConfig } from "shared/types/Organization";
import { logger } from "shared/utils/logger";
import { useModuleNav } from "shared/utils/module";

export const SidebarNav = () => {
    const coreRoutes = useModuleNav("core");
    const currentOrganization = useUserStore((s) => s.currentOrganization);
    const organizations = useUserStore((s) => s.user.organizations);
    const { setCurrentOrganization } = useUserStore.getState();
    const queryClient = useQueryClient();
    const givenName = useUserStore((s) => s.user.givenName);
    const surname = useUserStore((s) => s.user.surname);
    const settingsRoutes = useModuleNav("Settings");
    const { resetStore } = useUserStore.getState();
    const { signOut } = useSignOut({
        onSuccess: () => {
            resetStore();
            coreRoutes.login.nav();
        },
    });

    const changeOrg = useConstCallback(async (orgId) => {
        const org = organizations.find((org) => org.id === orgId);

        if (!org) return;

        setCurrentOrganization({
            id: org.id,
            name: org.name,
            nfirsConfig: {} as OrganizationNfirsConfig,
            locationData: org.locationData,
        });

        await queryClient.invalidateQueries();
    });

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    return (
        <AppShell.Navbar p="md" bg="var(--rms-sidebar-bg)" withBorder={false}>
            <AppShell.Section>
                <Stack gap="xl" mt="md">
                    <Box px="lg" h={35}>
                        <img style={{ height: "35px" }} src={logo} onClick={() => coreRoutes.base.nav()} />
                    </Box>
                    <Combobox
                        store={combobox}
                        withinPortal={false}
                        onOptionSubmit={(val) => {
                            changeOrg(val)
                                .catch(logger.error)
                                .finally(() => combobox.closeDropdown());
                        }}
                    >
                        <Combobox.Target>
                            <InputBase
                                mt="md"
                                style={{ overflow: "hidden" }}
                                component="button"
                                type="button"
                                pointer
                                rightSection={<Combobox.Chevron />}
                                onClick={() => combobox.toggleDropdown()}
                                rightSectionPointerEvents="none"
                            >
                                <Text lineClamp={1} fz={"lg"}>
                                    {currentOrganization.name || <Input.Placeholder>Pick organization</Input.Placeholder>}
                                </Text>
                            </InputBase>
                        </Combobox.Target>
                        <Combobox.Dropdown>
                            <Combobox.Options>
                                {organizations.map((org) => (
                                    <Combobox.Option value={org.id} key={org.id}>
                                        {org.name}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </Combobox.Dropdown>
                    </Combobox>
                </Stack>
            </AppShell.Section>
            <AppShell.Section grow my="md" component={ScrollArea}>
                <SidebarLinkGroup />
            </AppShell.Section>
            <AppShell.Section>
                <Menu shadow="md" width={200}>
                    <Menu.Target>
                        <Group align="center" w="100%" gap="md" style={{ cursor: "pointer" }}>
                            <Avatar src={null} alt={`${givenName} ${surname}`}>
                                {givenName[0] + surname[0]}
                            </Avatar>
                            {givenName} {surname}
                        </Group>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item onClick={() => settingsRoutes.browseCustomFields.nav()}>Settings</Menu.Item>
                        <Menu.Item onClick={() => signOut()}>Log Out</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </AppShell.Section>
        </AppShell.Navbar>
    );
};
