import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Incident Types",
    slug: "incident-type",
    queryKeys: {},
    ingresses: {
        base: () => `/`,
        tab: (tab) => `/${tab}`,
        tabDetail: (tab, id) => `/${tab}/${id}`,
    },
    routes: [
        <Route
            key="incidentTypeRoot"
            index
            Component={lazy(() => import("modules/IncidentType/IncidentType").then((module) => ({ default: module.IncidentType })))}
        />,
        <Route
            key="incidentTypeTags"
            path="tags"
            Component={lazy(() => import("modules/IncidentType/IncidentType").then((module) => ({ default: module.IncidentType })))}
        />,
        <Route
            key="incidentTypeNfirs"
            path="nfirs"
            Component={lazy(() => import("modules/IncidentType/IncidentType").then((module) => ({ default: module.IncidentType })))}
        />,
        <Route
            key="incidentTypeNfirsId"
            path="/incident-type/nfirs/:id"
            Component={lazy(() =>
                import("modules/IncidentType/nfirs/NfirsCodeTable").then((module) => ({ default: module.NfirsCodeTable })),
            )}
        />,
        <Route
            key="incidentTypeNeris"
            path="neris"
            Component={lazy(() => import("modules/IncidentType/IncidentType").then((module) => ({ default: module.IncidentType })))}
        />,
    ],
    sidebar: {
        hiddenUnlessNested: true,
        visibilityRequiresReadAccessToAnyOf: ["incidentType"],
    },
} as const satisfies ModuleSpec;
