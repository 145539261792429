import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Personnel Statuses",
    slug: "personnel-status",
    resource: "personnelStatus",
    queryKeys: {
        tableData: "personnelStatusTableData",
    },
    ingresses: {
        base: () => "" as const,
        new: () => "/new" as const,
        edit: (personnelStatusId: string) => `/${personnelStatusId}` as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["personnelStatus"],
        hiddenUnlessNested: true,
    },
    routes: [
        <Route
            key="personnelStatusTable"
            index
            Component={lazy(() =>
                import("modules/PersonnelStatus/PersonnelStatusTable").then((module) => ({ default: module.PersonnelStatusTable })),
            )}
        />,
        <Route
            key="personnelStatusDetail"
            path=":statusId"
            Component={lazy(() =>
                import("modules/PersonnelStatus/PersonnelStatusBuilder/PersonnelStatusBuilder").then((module) => ({
                    default: module.PersonnelStatusBuilder,
                })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
