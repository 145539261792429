import { BwsTopic } from "shared/bws/topic";
import { addressSchema } from "shared/types/Common";
import { coordinatesSchema, multipolygonSchema, pointSchema, polygonSchema } from "shared/types/geojson";
import { hydrantColorKeySchema } from "shared/types/Hydrant";
import { subPropertySchema } from "shared/types/Property";
import { mapToZodLiteralUnion } from "shared/utils/zod";
import { z } from "zod";

const layerTypeSchema = mapToZodLiteralUnion([
    "hydrants",
    "properties",
    "users",
    "apparatus",
    "self",
    "jobs",
    "stations",
    "agencies",
    "agencyOpenJobs",
] as const);

const mapClientTypeSchema = mapToZodLiteralUnion(["user", "apparatus"]);
const jobTypeSchema = mapToZodLiteralUnion(["fire", "ems", "water", "info", "police"]);

const jobTypeInformationSchema = z.object({
    type: jobTypeSchema,
    description: z.string().nullable(),
    code: z.string(),
    section: z.string().nullable(),
});

export const hydrantMapLayerItemSchema = z.object({
    id: z.string(),
    location: pointSchema,
    mainSize: z.number().optional(),
    color: hydrantColorKeySchema,
});

const clientMapLayerItemSchema = z.object({
    id: z.string(),
    location: pointSchema,
    initials: z.string(),
    name: z.string(),
    ts: z.date(),
    type: mapClientTypeSchema.default("user"),
});

const jobMapLayerItemSchema = z.object({
    id: z.string(),
    location: pointSchema,
    unitShortNames: z.string().array(),
    type: jobTypeInformationSchema,
});

const stationMapLayerItemSchema = z.object({
    id: z.string(),
    location: pointSchema,
    name: z.string(),
});

const agencyMapLayerItemSchema = z.object({
    id: z.string(),
    location: z.union([polygonSchema, multipolygonSchema]),
    name: z.string(),
});

export const propertyMapLayerItemSchema = z.object({
    id: z.string(),
    location: pointSchema,
    name: z.string().nullish(),
    type: z.string().nullish(),
    status: z.string().nullish(),
    notes: z.string().nullish(),
    pinType: z.string().nullish(),
    rmsAddress: addressSchema,
    contactInfos: z.object({ fullName: z.string(), phoneNumber: z.string() }).array(),
    pointsOfInterest: subPropertySchema.array(),
    dispatchableAddress: subPropertySchema.array(),
});

const mapLayerItemSchema = z.union([
    hydrantMapLayerItemSchema,
    clientMapLayerItemSchema,
    jobMapLayerItemSchema,
    stationMapLayerItemSchema,
    agencyMapLayerItemSchema,
    propertyMapLayerItemSchema,
]);

const _mapLayerSchemaBase = z.object({
    success: z.boolean(),
});

const mapLayerSchema = z.discriminatedUnion("type", [
    z
        .object({
            type: z.literal("hydrants"),
            items: hydrantMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("properties"),
            items: propertyMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("users"),
            items: clientMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("apparatus"),
            items: clientMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("self"),
            items: clientMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("jobs"),
            items: jobMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("stations"),
            items: stationMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("agencies"),
            items: agencyMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
    z
        .object({
            type: z.literal("agencyOpenJobs"),
            items: jobMapLayerItemSchema.array().optional(),
        })
        .merge(_mapLayerSchemaBase),
]);

export const bwsRmsMapTopic = {
    name: "rmsMap",
    key: z.literal("rmsMaps"),
    params: z.object({
        ne: coordinatesSchema,
        sw: coordinatesSchema,
        layers: z.array(layerTypeSchema),
    }),
    initalData: mapLayerSchema.array(),
    serverUpdate: z.union([
        z.object({
            type: z.literal("update"),
            layer: layerTypeSchema,
            item: mapLayerItemSchema,
        }),
        z.object({
            type: z.literal("remove"),
            layer: layerTypeSchema,
            id: z.string(),
        }),
    ]),
} as const satisfies BwsTopic;
