import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Event Types",
    slug: "event-types",
    resource: "eventSubTypes",
    queryKeys: {},
    ingresses: {
        base: () => `/`,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["eventSubTypes"],
        hiddenUnlessNested: true,
    },
    routes: [
        <Route
            key="eventSubTypeTable"
            index
            Component={lazy(() =>
                import("modules/EventSubTypes/EventSubTypeTable").then((module) => ({ default: module.EventSubTypeTable })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
