import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { print } from "graphql";
import { useUserStore } from "shared/stores/userStore";
import { HttpError, HttpStatusCode } from "shared/types/Http";
import { QueryBody } from "shared/types/Query";
import { logger } from "shared/utils/logger";

type HttpRequestInit = RequestInit;

const http = async <T>(path: string, { headers, ...restConfig }: HttpRequestInit): Promise<T> => {
    const apiKey = useUserStore.getState().session.apiKey;
    const organizationId = useUserStore.getState().currentOrganization.id;
    const requestUrl = `${import.meta.env.VITE_API_BASE_URL}${path}`;
    const request = new Request(requestUrl, {
        ...restConfig,
        headers: {
            ...headers,
            "X-API-KEY": apiKey,
            "X-BRYX-TYPE": `${import.meta.env.VITE_SITE}`,
            "X-BRYX-ORGID": organizationId,
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new HttpError(response);
    }

    if (response.status === HttpStatusCode.NO_CONTENT) {
        return Promise.resolve({} as T);
    }

    return response.json().catch(logger.error);
};

export const httpGet = async <T>(path: string, config?: HttpRequestInit): Promise<T> => {
    const init = { method: "GET", headers: { "Content-Type": "application/json" }, ...config };

    return await http<T>(path, init);
};

export const httpPatch = async <T, U>(path: string, body: T, config?: HttpRequestInit): Promise<U> => {
    const init = { method: "PATCH", body: JSON.stringify(body), headers: { "Content-Type": "application/json" }, ...config };

    return await http<U>(path, init);
};

export const httpPut = async <T, U>(path: string, body: T, config?: HttpRequestInit): Promise<U> => {
    const init = { method: "PUT", body: JSON.stringify(body), headers: { "Content-Type": "application/json" }, ...config };

    return await http<U>(path, init);
};

export const httpPost = async <T, U>(path: string, body: T, config?: HttpRequestInit): Promise<U> => {
    const init = { method: "POST", body: JSON.stringify(body), headers: { "Content-Type": "application/json" }, ...config };

    return await http<U>(path, init);
};

export const httpPostFormData = async <T extends FormData, U>(path: string, body: T, config?: RequestInit): Promise<U> => {
    const init = { method: "POST", body, ...config };

    return await http<U>(path, init);
};

export const httpDelete = async <T>(path: string, config?: HttpRequestInit): Promise<T> => {
    const init = { method: "DELETE", headers: { "Content-Type": "application/json" }, ...config };

    return await http<T>(path, init);
};

export const httpQuery = async <U>(path: string, body: QueryBody, config?: HttpRequestInit): Promise<U> => {
    const init = { method: "QUERY", body: JSON.stringify(body), headers: { "Content-Type": "application/json" }, ...config };

    return await http<U>(path, init);
};

export const httpPostGraphql = async <T, V, U>(document: TypedDocumentNode<T, V>, variables: V, config?: RequestInit): Promise<U> => {
    const body = {
        query: print(document),
        variables,
    };
    const init = { method: "POST", body: JSON.stringify(body), headers: { "Content-Type": "application/json" }, ...config };

    return await http<U>("/graphql", init);
};
