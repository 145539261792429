import { Stack } from "@mantine/core";
import { registeredModuleSpecs } from "modules/Core/registrar";
import { SidebarNavFolder } from "modules/Core/sidebar/SidebarNavFolder";
import { SidebarNavItem } from "modules/Core/sidebar/SidebarNavItem";
import { useCallback } from "react";
import { useModulePermissionGetter } from "shared/stores/userStore";
import { match, P } from "ts-pattern";

export const SidebarLinkGroup = () => {
    const hasPermission = useModulePermissionGetter();
    const filteredSpecsForSideBar = useCallback(() => {
        return registeredModuleSpecs
            .filter((spec) => (import.meta.env.VITE_ENVIRONMENT === "prod" ? !spec.devOnly : true))
            .filter((spec) =>
                match(spec)
                    .with({ sidebar: { hiddenUnlessNested: true } }, () => false)
                    .with({ nestedModules: P.when((val) => Array.isArray(val) && val.length > 0) }, () => true)
                    .with(
                        { sidebar: { visibilityRequiresReadAccessToAnyOf: P.array(P.string) } },
                        ({ sidebar: { visibilityRequiresReadAccessToAnyOf: mods } }) =>
                            mods.some((mod) => hasPermission(mod, ["rmsReadModule"])),
                    )
                    .otherwise(() => true),
            );
    }, [registeredModuleSpecs, hasPermission]);

    return (
        <>
            <Stack py="lg" px="sm">
                {filteredSpecsForSideBar().map((spec) =>
                    match(spec)
                        .with({ nestedModules: P.when((val) => Array.isArray(val) && val.length > 0) }, (spec) => (
                            <SidebarNavFolder key={spec.slug} spec={spec} />
                        ))
                        .otherwise((spec) => <SidebarNavItem key={spec.slug} spec={spec} />),
                )}
            </Stack>
        </>
    );
};
