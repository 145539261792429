import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Companies",
    slug: "company",
    resource: "personnelCompany",
    queryKeys: {
        tableData: "personnelCompanyTable",
    },
    ingresses: {
        base: () => "" as const,
        new: () => "/new" as const,
        edit: (companyId: string) => `/${companyId}` as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["personnelCompany"],
        hiddenUnlessNested: true,
    },
    routes: [
        <Route
            key="personnelCompanyTable"
            index
            Component={lazy(() =>
                import("modules/PersonnelCompany/PersonnelCompanyTable").then((module) => ({ default: module.PersonnelCompanyTable })),
            )}
        />,
        <Route
            key="personnelCompanyDetail"
            path=":companyId"
            Component={lazy(() =>
                import("modules/PersonnelCompany/PersonnelCompanyBuilder/PersonnelCompanyBuilder").then((module) => ({
                    default: module.PersonnelCompanyBuilder,
                })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
