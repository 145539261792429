import { err, pipe } from "@tsly/core";
import { isSome, maybe } from "@tsly/maybe";
import { obj } from "@tsly/obj";
import { BwsInitialDataCache, BwsSubscriptionLedger, RegisteredTopic, registeredTopics, UseBwsTopicOpts } from "shared/bws/core";
import { bwsMessageType } from "shared/bws/messageType";
import { BwsTopic } from "shared/bws/topic";
import { useUserStore } from "shared/stores/userStore";
import { logger } from "shared/utils/logger";
import { tryParseJSONObject } from "shared/utils/object";
import { match } from "ts-pattern";
import { z } from "zod";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

/**
 * Collection of tools to maintain the health of the BWS connection
 */
type BwsHealthKit = {
    /**
     * The current health of the websocket. True when the client is reciving frequent enough pings to satisfy the {@link BwsHealthKit.latencyTolerance latencyTolerance} threshold, otherwise false.
     */
    healthy: boolean;
    /**
     * How often, in milliseconds, to dispatch pings and check for disconnection
     */
    tick: number;
    /**
     * How long, in milliseconds, is permitted without a ping response
     */
    latencyTolerance: number;
    /**
     * The timestamp (in milliseconds) of the last time a ping response was recivied. If a ping response was never received then the time will be set to the time the store was initialized.
     */
    timeLastPingRecd: number;
    /**
     * The dispatch logic for sending a ping request
     */
    sendPing: () => void;
    /**
     * The underlying NodeJS.Timeout instance (interval). If the BWS connection has not be started yet, then `null`.
     */
    timer: NodeJS.Timeout | null;
};

type BwsStore = {
    /**
     * The underlying websocket connection.
     */
    connection: WebSocket | null;
    /**
     * The ledger of which subscriptions are bound to which topics
     */
    subscriptionLedger: BwsSubscriptionLedger;
    /**
     * The cache of initial data to dispatch to new subscriptions that are utilizing an existing topic subscription
     */
    initialDataCache: BwsInitialDataCache;
    /**
     * The counter tracking the unique identifier for client-sent websocket messages.
     *
     * See also: [BWS Message Format](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#message-format)
     */
    messageCounter: number;
    /**
     * The counter tracking the unique identifiers for {@link BwsTopicSubscription}
     */
    subscriptionCounter: number;
    /**
     * The {@link BwsHealthKit} for mantaining the connection's health
     */
    healthkit: BwsHealthKit;
    /**
     * Registers a new {@link BwsTopicSubscription} to the {@link BwsSubscriptionLedger}.
     *
     * If no topics with the given key exist in the ledger, then a [subscribe request message](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#subscribing-to-topics) will be sent to the server.
     *
     * @param key The topic key to subscribe with
     * @param opts Additional options for opening the subscription
     * @returns A unique identifier representing the subscription in the ledger
     */
    openSubscription: <TTopicKey extends z.infer<RegisteredTopic["key"]>>(key: TTopicKey, opts: UseBwsTopicOpts<TTopicKey>) => number;
    /**
     * Removes a {@link BwsTopicSubscription} from the {@link BwsSubscriptionLedger}
     *
     * After removal, if no topics with the key associated with the removed subscription exist in the ledger, an unsubscribe request is sent to the server.
     *
     * @param subscriptionId The unique identifier representing the subscription in the ledger to remove
     */
    closeSubscription: (subscriptionId: number) => void;

    // internal helpers

    /**
     * @internal
     */
    sendMessage: (data: string) => Promise<void>;
    /**
     * @internal
     */
    startConnection: () => void;
};

export const bwsStore = create<BwsStore>()(
    devtools(
        immer((set, get) => ({
            connection: null,
            subscriptionLedger: {},
            initialDataCache: {},
            messageCounter: 0,
            subscriptionCounter: 0,

            healthkit: {
                healthy: true,
                tick: 1000 * 20, // 20s
                latencyTolerance: 1000 * 40, // 40s
                timeLastPingRecd: +new Date(),
                timer: null,
                sendPing: () =>
                    set((store) => {
                        void store.sendMessage(
                            JSON.stringify({
                                id: store.messageCounter,
                                type: bwsMessageType.pingRequest,
                            }),
                        );

                        store.messageCounter++;
                    }),
            },

            startConnection: () => {
                set((store) => {
                    const connection = new WebSocket(
                        `${import.meta.env.VITE_WS_BASE_URL}?apiKey=${useUserStore.getState().session.apiKey}&bryxType=rms&organizationId=${
                            useUserStore.getState().currentOrganization.id
                        }`,
                    );

                    connection.onmessage = ({ data }) => {
                        /**
                         * Maps the given `topics` tuple into a tuple of associated {@link z.ZodObject}s in the shape of
                         *
                         * ```
                         * typeof z.object({
                         *  type: bwsMessageType.serverUpdates,
                         *  topic: bwsTopic.key,
                         *  data: bwsTopic.serverUpdate
                         * });
                         * ```
                         */
                        const _mapTopicsIntoZodObjects = <TTopics extends readonly [...BwsTopic[]]>(topics: TTopics) => {
                            type MappedServerUpdateSchema<
                                TTuple extends readonly [...BwsTopic[]],
                                TMapped extends [...unknown[]] = [],
                            > = TTuple extends readonly [infer THead extends BwsTopic, ...infer TRest extends [...BwsTopic[]]]
                                ? MappedServerUpdateSchema<
                                      TRest,
                                      [
                                          ...TMapped,
                                          z.ZodObject<{
                                              type: z.ZodLiteral<(typeof bwsMessageType)["serverUpdate"]>;
                                              topic: THead["key"];
                                              data: THead["serverUpdate"];
                                          }>,
                                          z.ZodObject<{
                                              type: z.ZodLiteral<(typeof bwsMessageType)["subscribeResponse"]>;
                                              topic: THead["key"];
                                              ok: z.ZodBoolean;
                                              initialData: THead["initalData"];
                                          }>,
                                      ]
                                  >
                                : TMapped;

                            return topics.flatMap((topic) => [
                                z.object({
                                    type: z.literal(bwsMessageType.serverUpdate),
                                    topic: topic.key,
                                    data: topic.serverUpdate,
                                }),
                                z.object({
                                    type: z.literal(bwsMessageType.subscribeResponse),
                                    topic: topic.key,
                                    ok: z.boolean(),
                                    initialData: topic.initalData,
                                }),
                            ]) as MappedServerUpdateSchema<typeof registeredTopics>;
                        };

                        const bwsServerSentMsg = z.union([
                            z.object({
                                type: z.literal(bwsMessageType.pingResponse),
                                replyTo: z.number(),
                                ok: z.boolean(),
                            }),
                            z.object({
                                type: z.literal(bwsMessageType.invalidRequest),
                                replyTo: z.number(),
                                error: z.string(),
                            }),
                            z.object({
                                type: z.literal(bwsMessageType.serverAck),
                            }),
                            z.object({
                                type: z.literal(bwsMessageType.unsubscribeResponse),
                            }),
                            ..._mapTopicsIntoZodObjects(registeredTopics),
                        ]);

                        // handle server messages

                        // check if message is valid JSON string
                        if (tryParseJSONObject(data as string)) {
                            match(bwsServerSentMsg.parse(JSON.parse(z.string().parse(data))))
                                .with({ type: bwsMessageType.pingResponse }, ({ ok }) => {
                                    if (ok) {
                                        return set((store) => {
                                            store.healthkit.healthy = true;
                                            store.healthkit.timeLastPingRecd = +new Date();
                                        });
                                    }
                                })
                                .with({ type: bwsMessageType.subscribeResponse }, (msg) => {
                                    const { initialDataCache } = get();

                                    set((store) => {
                                        store.subscriptionLedger[msg.topic]?.forEach((subscription) => {
                                            if (!subscription.initialDataEmitted) {
                                                subscription.onInitialData?.(obj(msg.initialData).cast());
                                            }

                                            subscription.initialDataEmitted = true;
                                        });
                                    });

                                    set({
                                        initialDataCache: { ...initialDataCache, [msg.topic]: msg.initialData },
                                    });
                                })
                                .with({ type: bwsMessageType.serverUpdate }, (msg) => {
                                    // pass along payload to all subscribers

                                    get().subscriptionLedger[msg.topic]?.forEach((sub) => {
                                        sub.onServerUpdate?.(obj(msg.data).cast());
                                    });
                                })
                                .with({ type: bwsMessageType.invalidRequest }, (msg) => {
                                    logger.warn("BWS Error", { msg });
                                })
                                .otherwise(() => null);
                        }
                    };

                    connection.onerror = () => {
                        get().connection?.close();
                    };

                    store.connection = connection;

                    if (isSome(store.healthkit.timer)) clearInterval(store.healthkit.timer);
                    store.healthkit.timer = setInterval(() => {
                        const { sendPing, latencyTolerance, timeLastPingRecd, healthy } = get().healthkit;

                        sendPing();

                        if (healthy && +new Date() - timeLastPingRecd > latencyTolerance) {
                            // unacceptable latency-- attempt to reconnect.
                            set((store) => {
                                store.healthkit.healthy = false;
                            });
                        }
                    }, store.healthkit.tick);
                });
            },
            openSubscription: (key, opts) => {
                const { subscriptionLedger, subscriptionCounter, initialDataCache } = get();

                try {
                    // check for existing subscription
                    if (
                        !Object.values(subscriptionLedger)
                            .flat()
                            .some((sub) => sub.key == key)
                    ) {
                        set((store) => {
                            void store.sendMessage(
                                JSON.stringify({
                                    type: bwsMessageType.subscribeRequest,
                                    id: store.messageCounter++,
                                    version: 0,
                                    topic: key,
                                    params: obj(opts).getUntypedProperty("params") ?? undefined,
                                }),
                            );
                        });
                    }

                    const cachedInitialData = initialDataCache[key];

                    if (cachedInitialData) {
                        opts.onInitialData?.(obj(cachedInitialData).cast());
                    }

                    // note: since subscriptionLedger has values which contain keys that are `readonly`, we cannot use `immer` since `immer` deeply
                    // removes all `readonly` flags on all their draft proxies
                    const ledgerPatch = [
                        {
                            key,
                            onServerUpdate: opts.onServerUpdate,
                            onInitialData: opts.onInitialData,
                            subscriptionId: subscriptionCounter,
                            initialDataEmitted: !!cachedInitialData,
                        },
                    ];
                    set({
                        subscriptionLedger: {
                            ...subscriptionLedger,
                            [key]: subscriptionLedger[key]?.concat(ledgerPatch) ?? ledgerPatch,
                        },
                        subscriptionCounter: subscriptionCounter + 1,
                    });

                    return subscriptionCounter;
                } catch (ex: unknown) {
                    return err(`openSubscription failed: ${(ex as Error).message}`);
                }
            },
            closeSubscription: (id) => {
                set((store) => {
                    // first, look up subscription by id and remove it from the ledger

                    const subscription = Object.values(store.subscriptionLedger)
                        .flat()
                        .find(({ subscriptionId }) => subscriptionId == id);

                    if (!subscription) return;

                    pipe(store.subscriptionLedger[subscription.key], (it) => {
                        maybe(it?.findIndex((ent) => ent.subscriptionId == subscription.subscriptionId))?.take((idx) => {
                            store.subscriptionLedger[subscription.key]?.splice(idx, 1);
                        });
                    });

                    // then, check if the underlying topic subscription on the connection needs to be cleaned up

                    if (
                        !Object.values(store.subscriptionLedger)
                            .flat()
                            .some(({ key }) => key == subscription.key)
                    ) {
                        void get().sendMessage(
                            JSON.stringify({
                                id: store.messageCounter++,
                                type: bwsMessageType.unsubscribeRequest,
                                topic: subscription.key,
                            }),
                        );

                        // also clear out the inital data cache for this topic
                        delete store.initialDataCache[subscription.key];
                    }
                });
            },
            /**
             * Safely queue a message to be sent via the internal underlying `connection`.
             *
             * If the `connection` is not in ready state 1, then the message will continue to try to be sent again every second.
             *
             * @param data The message data to send
             * @returns A promise, which resolves when the message is actually sent
             */
            sendMessage: (data) => {
                return new Promise((resolve) => {
                    const _waitForConnection = () => {
                        const { connection } = get();

                        if (connection?.readyState == WebSocket.OPEN) {
                            connection.send(data);
                            resolve();
                        } else {
                            setTimeout(() => _waitForConnection(), 1000); // 1s
                        }
                    };

                    _waitForConnection();
                });
            },
        })),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "BWS" },
    ),
);
