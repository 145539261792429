import { ActionIcon, Collapse, Group, Stack, useMantineTheme } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { RegisteredModule } from "modules/Core/registrar";
import classes from "modules/Core/sidebar/SidebarNav.module.css";
import { SidebarNavItem } from "modules/Core/sidebar/SidebarNavItem";
import { useCallback, useEffect, useState } from "react";
import { useModulePermissionGetter } from "shared/stores/userStore";
import { useActiveModuleSpec } from "shared/utils/module";
import { match, P } from "ts-pattern";

type SidebarNavFolderProps = {
    spec: Extract<RegisteredModule, { nestedModules: readonly RegisteredModule[] }>;
};

export const SidebarNavFolder = ({ spec }: SidebarNavFolderProps) => {
    const theme = useMantineTheme();
    const curModule = useActiveModuleSpec();
    const hasPermission = useModulePermissionGetter();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!spec.nestedModules.some((it) => it.name == curModule?.name)) setOpen(false);
    }, [curModule]);

    const filteredSpecsByPermission = useCallback(() => {
        return spec.nestedModules
            .filter((spec) => (import.meta.env.VITE_ENVIRONMENT === "prod" ? !spec.devOnly : true))
            .filter((spec) =>
                match(spec)
                    .with(
                        { sidebar: { visibilityRequiresReadAccessToAnyOf: P.array(P.string) } },
                        ({ sidebar: { visibilityRequiresReadAccessToAnyOf: mods } }) =>
                            mods.some((mod) => hasPermission(mod, ["rmsReadModule"])),
                    )
                    .otherwise(() => true),
            );
    }, [spec.nestedModules, hasPermission]);

    if (filteredSpecsByPermission().length === 0) {
        return null;
    }

    return (
        <>
            <Group justify="space-between" align="center" gap="sm" wrap="nowrap">
                <SidebarNavItem spec={spec} isOpen={open} />
                <ActionIcon variant="subtle" size="lg" onClick={() => setOpen((o) => !o)}>
                    <IconChevronDown className={classes.chevron} size="16" data-active={open} />
                </ActionIcon>
            </Group>
            <Collapse in={open}>
                <Stack ml={theme.spacing["3xl"]}>
                    {filteredSpecsByPermission().map((nestedSpec) => (
                        <SidebarNavItem key={nestedSpec.slug} spec={nestedSpec} isOpen={open} />
                    ))}
                </Stack>
            </Collapse>
        </>
    );
};
