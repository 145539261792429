import { match } from "ts-pattern";
import { z } from "zod";

type TupleMappedToZodLiterals<
    TTuple extends ReadonlyArray<string>,
    TMapped extends ReadonlyArray<z.ZodLiteral<string>> = [],
> = TTuple extends readonly [infer THead extends string, ...infer TRest extends ReadonlyArray<string>]
    ? TupleMappedToZodLiterals<TRest, [...TMapped, z.ZodLiteral<THead>]>
    : TMapped;

export function mapToZodLiteralUnion<const TTuple extends readonly [string, string, ...string[]]>(tuple: TTuple) {
    return z.union([...(tuple.map((v) => z.literal(v)) as TupleMappedToZodLiterals<typeof tuple>)]);
}

export function nonemptyString(msg?: string) {
    return z.string().min(1, msg ?? "Required");
}

export namespace TimeString {
    export function zSchema(): z.ZodBranded<z.ZodEffects<z.ZodString, string, string>, "TIME_STRING">;
    export function zSchema(options?: { withSec: true }): z.ZodBranded<z.ZodEffects<z.ZodString, string, string>, "TIME_STRING+SEC">;

    export function zSchema(options?: { withSec?: boolean }) {
        return z
            .string()
            .refine((it) => {
                return !!TimeString.parse(it, options);
            })
            .brand(options?.withSec ? "TIME_STRING_SEC" : "TIME_STRING");
    }

    export function emitZodIssue(ctx: z.RefinementCtx, options?: { withSec?: boolean }) {
        ctx.addIssue({
            code: "custom",
            fatal: true,
            message: "Invalid time. Expected HH:MM" + (options?.withSec ? " :SS" : ""),
        });

        return new Date();
    }

    export function parse(val: string & z.BRAND<"TIME_STRING">): number;
    export function parse(val: string & z.BRAND<"TIME_STRING_SEC">, options: { withSec: true }): number;
    export function parse(val: string, options?: { withSec?: boolean }): number | undefined;

    export function parse(val: string, options?: { withSec?: boolean }): number | undefined {
        const expectedLength = options?.withSec ? 8 : 5;
        const expectedParts = options?.withSec ? 3 : 2;

        if (val.length != expectedLength) {
            return;
        }

        const parts = val.split(":");
        if (parts.some((part) => part.length != 2 || [...part].map((each) => parseInt(each)).some(isNaN))) {
            return;
        }

        if (parts.length != expectedParts) {
            return;
        }

        const date = new Date();
        date.setHours(parseInt(parts[0]));
        date.setMinutes(parseInt(parts[1]));
        date.setSeconds(options?.withSec ? parseInt(parts[2]) : 0);

        return +date;
    }

    export function from(value: number | Date): string & z.BRAND<"TIME_STRING">;
    export function from(value: number | Date, options: { withSec: true }): string & z.BRAND<"TIME_STRING+SEC">;

    export function from(value: number | Date, options?: { withSec?: boolean }) {
        value = typeof value == "number" ? new Date(value) : value;
        const str = `${value.getHours().toString().padStart(2, "0")}:${value.getMinutes().toString().padStart(2, "0")}`;

        if (options?.withSec) {
            return str + `:${value.getSeconds.toString().padStart(2, "0")}`;
        }

        return str;
    }
}

export const moduleZodRawShape = {
    id: z.string().default(""),
    name: nonemptyString(),
    description: z.string().nullish(),
    agencyId: z.string().default(""),
    customFields: z.any().default({}),
};

export function zodLiteralUnionToValues<T extends readonly [z.ZodLiteral<string>, ...z.ZodLiteral<string>[]]>(
    schema: z.ZodUnion<T>,
): z.infer<z.ZodUnion<T>>[] {
    return schema._def.options.map((each) => each._def.value);
}

export function emitZodIssue(message: string, options: { ctx: z.RefinementCtx; path?: string[] | string }) {
    options.ctx.addIssue({
        code: "custom",
        fatal: true,
        path: typeof options.path == "string" ? [options.path] : options.path,
        message,
    });
}

/**
 * Returns a prefab utility zod refinement function for use with zod `superRefine`.
 *
 * ### Strategies
 * - `all-for-one`: Will error every field if *any* field is null, unless *every* field is null
 * - `one-for-one`: Will error any field that is null
 *
 * @param options
 * @returns
 */
export function zodObjectNullFieldRefiner<T extends object>(options: { strategy: "all-for-one" | "one-for-one"; relativePath?: string[] }) {
    return match(options.strategy)
        .with("one-for-one", () => {
            return function (state: T, ctx: z.RefinementCtx) {
                Object.entries(state).forEach(([path, value]) => {
                    if (value == null) emitZodIssue("Required", { ctx, path: (options.relativePath ?? []).concat([path]) });
                });
            };
        })
        .with("all-for-one", () => {
            return function (state: T, ctx: z.RefinementCtx) {
                const passed = Object.values(state).every((value) => value != null) || Object.values(state).every((value) => value == null);
                if (!passed) {
                    Object.keys(state).forEach((path) =>
                        emitZodIssue("Required", { ctx, path: (options.relativePath ?? []).concat(path) }),
                    );
                }
            };
        })
        .exhaustive();
}
