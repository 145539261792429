import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Hydrants",
    slug: "hydrants",
    resource: "hydrants",
    queryKeys: {},
    ingresses: {
        base: () => "",
        new: () => `/new`,
        edit: (hydrantId: string) => `/${hydrantId}`,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["hydrants"],
        hiddenUnlessNested: true,
        displayName: "Hydrant Records",
    },
    routes: [
        <Route
            key="hydrants"
            index
            Component={lazy(() => import("modules/Hydrant/Hydrants").then((module) => ({ default: module.Hydrants })))}
        />,
        <Route
            key="hydrantBuilder"
            path=":hydrantId"
            Component={lazy(() =>
                import("modules/Hydrant/HydrantBuilder/HydrantBuilder").then((module) => ({ default: module.HydrantBuilder })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
