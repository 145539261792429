import { BwsTopic } from "shared/bws/topic";
import { mapToZodLiteralUnion } from "shared/utils/zod";
import { z } from "zod";

const services = ["rms", "b911Android", "b911IOS", "universal", "scu", "supportTools"] as const;

/**
 * See: [ServiceVersionTopic](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/ServiceTopic.md) | [ServiceType](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/ServiceTopic.md#servicetype)
 */
const serviceNameSchema = mapToZodLiteralUnion(services);

/**
 * See: [ServiceVersionTopic](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/ServiceTopic.md) | [ServiceObject](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/ServiceTopic.md#service-object)
 */
const serviceVersionObjectSchema = z.object({
    id: z.string(),
    service: serviceNameSchema,
    versionCode: z.string(),
    versionName: z.string(),
    versionNotes: z.string(),
    releasedDate: z.number(),
});

type PrefixedTuple<
    TTuple extends readonly [...string[]],
    TPrefix extends string,
    TMapped extends readonly [...string[]] = [],
> = TTuple extends readonly [infer THead extends string, ...infer TRest extends readonly string[]]
    ? PrefixedTuple<TRest, TPrefix, [...TMapped, `${TPrefix}${THead}`]>
    : TMapped;

export const bwsServiceVersionTopic = {
    name: "service-version",
    key: mapToZodLiteralUnion(services.map((svc) => `versions/${svc}`) as PrefixedTuple<typeof services, "versions/">),
    initalData: serviceVersionObjectSchema,
    serverUpdate: serviceVersionObjectSchema,
} as const satisfies BwsTopic;
