import { Button, Group } from "@mantine/core";
import { IconArrowRight, IconChartPie, IconDatabaseOff } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "shared/stores/userStore";
import { useModuleNav } from "shared/utils/module";

export const ErrorNavigation = () => {
    const navigate = useNavigate();
    const dashboardRoutes = useModuleNav("Dashboard");
    const coreRoutes = useModuleNav("core");
    const { resetStore } = useUserStore.getState();

    return (
        <Group gap="lg">
            {import.meta.env.VITE_ENVIRONMENT !== "prod" && (
                <Button
                    leftSection={<IconDatabaseOff size="16" />}
                    onClick={() => {
                        resetStore();
                        coreRoutes.login.nav();
                    }}
                >
                    Dev Tool: Clear Local Storage
                </Button>
            )}
            <Button leftSection={<IconChartPie size="16" />} onClick={() => dashboardRoutes.base.nav()}>
                Go to Dashboard
            </Button>
            <Button variant="outline" rightSection={<IconArrowRight size="16" />} onClick={() => navigate(-1)}>
                Go Back
            </Button>
        </Group>
    );
};
