import { Stack, useMantineTheme } from "@mantine/core";
import { ColorModeController } from "modules/Core/ColorModeController";
import { ReactNode } from "react";

export const ErrorWrapper = ({ children }: { children: ReactNode }) => {
    const theme = useMantineTheme();

    return (
        <ColorModeController>
            <Stack h="100vh" w="100vw" align="center" justify="center" gap={theme.spacing["5xl"]} bg="var(--rms-page-bg)">
                {children}
            </Stack>
        </ColorModeController>
    );
};
