import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Properties",
    slug: "properties",
    queryKeys: {},
    ingresses: {
        base: () => "",
        new: () => `/new`,
        edit: (propertyId: string) => `/${propertyId}`,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["properties"],
        hiddenUnlessNested: true,
        displayName: "Property Records",
    },
    routes: [
        <Route
            key="propertyIndex"
            index
            Component={lazy(() => import("modules/Property/PropertyTable").then((mod) => ({ default: mod.PropertyTable })))}
        />,
        <Route
            key="propertyBuilder"
            path=":propertyId"
            Component={lazy(() =>
                import("modules/Property/PropertyBuilder/PropertyBuilder").then((mod) => ({ default: mod.PropertyBuilder })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
