import { Notification, NotificationProps, Text, useMantineColorScheme } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import React from "react";
import { ReactNode } from "react";
import { toast } from "sonner";
import { match } from "ts-pattern";

const StyledToast = (props: {
    withCloseButton: boolean;
    title: ReactNode;
    t: string | number;
    iconProps?: { icon: React.JSX.Element; color: string };
    children: ReactNode;
}) => {
    const { colorScheme } = useMantineColorScheme();

    return (
        <Notification
            bg={colorScheme == "dark" ? "dark.8" : "white"}
            withCloseButton={props.withCloseButton}
            onClose={() => toast.dismiss(props.t)}
            key={props.t}
            title={
                <Text fz="lg" fw="600" pl="8px">
                    {props.title}
                </Text>
            }
            {...props.iconProps}
        >
            <Text c="var(--rms-color-text-secondary)" pl="8px">
                {props.children}
            </Text>
        </Notification>
    );
};

type CreateToastProps = {
    options?: Parameters<(typeof toast)["custom"]>[1];
    variant: "success" | "error" | "info";
} & Omit<NotificationProps, "variant">;

export const createToast = ({ variant, options, withCloseButton = true, title, children }: CreateToastProps) => {
    const iconProps = match(variant)
        .with("success", () => ({
            icon: <IconCheck size="20" />,
            color: "green.5",
        }))
        .with("error", () => ({
            icon: <IconX size="20" />,
            color: "red.5",
        }))
        .with("info", () => undefined)
        .exhaustive();

    return toast.custom(
        (t) => {
            return (
                <StyledToast iconProps={iconProps} withCloseButton={withCloseButton} t={t} title={title} key={t}>
                    {children}
                </StyledToast>
            );
        },
        {
            duration: 5000,
            style: { backgroundColor: "transparent", zIndex: undefined },
            className: "toast-container",
            ...options,
        },
    );
};
