import { ReactNode, useEffect } from "react";
import { useColorMode } from "shared/hooks/useColorMode";

export const ColorModeController = ({ children }: { children?: ReactNode }) => {
    const colorMode = useColorMode();

    useEffect(() => {
        if (colorMode === "dark") {
            document.body.classList.remove("light");
            document.body.classList.add(colorMode);
        } else {
            document.body.classList.remove("dark");
            document.body.classList.add(colorMode);
        }
    }, [colorMode]);

    return <>{children}</>;
};
