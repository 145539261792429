export const bwsMessageType = {
    /**
     * A response denoting an error
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    invalidRequest: -1,
    /**
     * Request a subscription to a given topic
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    subscribeRequest: 0,
    /**
     * A response detailing what happened during the subscribe request
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    subscribeResponse: 1,
    /**
     * Remove a subscription to a given topic
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    unsubscribeRequest: 2,
    /**
     * A response detailing what happened during the unsubscribe request
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    unsubscribeResponse: 3,

    // reserve 4

    /**
     * An update from the server to one of the subscribed topics
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    serverUpdate: 5,
    /**
     * An update from the client to one of the subscribed topics
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    clientUpdate: 6,
    /**
     * An acknowledgement from the server in response to a client update
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    serverAck: 7,
    /**
     * A ping request sent from client to server
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    pingRequest: 8,
    /**
     * A ping response sent from server to client
     *
     * See: [BryxWebSocket - Types](https://gitlab.com/bryxinc/bryx911/bryx911-spec/-/blob/master/WebSockets/BryxWebSocket.md#types)
     */
    pingResponse: 9,
} as const satisfies Record<string, number>;
