import { IconBuildingStore } from "@tabler/icons-react";
import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Vendors",
    slug: "vendors",
    icon: {
        default: IconBuildingStore,
        filled: IconBuildingStore,
    },
    resource: "vendors",
    queryKeys: {
        tableData: "vendorTableData",
    },
    ingresses: {
        base: () => "" as const,
        new: () => "/new" as const,
        edit: (vendorId: string) => `/${vendorId}` as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["vendors"],
    },
    routes: [
        <Route
            key="vendorTable"
            index
            Component={lazy(() => import("modules/Vendor/VendorTable").then((module) => ({ default: module.VendorTable })))}
        />,
        <Route
            key="vendorDetail"
            path=":vendorId"
            Component={lazy(() => import("modules/Vendor/builder/VendorBuilder").then((module) => ({ default: module.VendorBuilder })))}
        />,
    ],
} as const satisfies ModuleSpec;
