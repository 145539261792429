import { IconSettings, IconSettingsFilled } from "@tabler/icons-react";
import { lazy } from "react";
import { Route } from "react-router-dom";
import { CustomFieldModule } from "shared/types/CustomField";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Settings",
    slug: "settings",
    icon: {
        default: IconSettings,
        filled: IconSettingsFilled,
    },
    queryKeys: { accountData: "getUserAccountSettings" },
    sidebar: {
        hiddenUnlessNested: true,
    },
    ingresses: {
        base: () => "/browse" as const,
        browseCustomFields: () => "/browse/custom-fields" as const,
        customFields: (module: string) => `/manage/custom-fields/${module}` as const,
        customize: (module: string) => `/manage/custom-fields/${module as CustomFieldModule}/customize` as const,
    },
    routes: [
        <Route path="browse" key="browse">
            <Route
                path="custom-fields"
                Component={lazy(() =>
                    import("modules/Settings/custom-fields/BrowseCustomFields").then((module) => ({ default: module.BrowseCustomFields })),
                )}
                key={"browseCustomFields"}
            />
            ,
        </Route>,
        <Route path="manage" key="manage">
            <Route path="custom-fields" key={"customFields"}>
                <Route
                    path=":moduleName"
                    Component={lazy(() =>
                        import("modules/Settings/custom-fields/CustomFields").then((module) => ({ default: module.CustomFields })),
                    )}
                    key={"companyCustomFields"}
                />
                <Route
                    path=":moduleName/customize"
                    Component={lazy(() =>
                        import("modules/Settings/custom-fields/CustomFieldCustomizeForm").then((module) => ({
                            default: module.CustomFieldCustomizeForm,
                        })),
                    )}
                    key="customFieldsCustomizeForm"
                />
            </Route>
        </Route>,
    ],
} as const satisfies ModuleSpec;
