import { QueryBody } from "shared/types/Query";

export const queryKeyFactory = <const T extends string>(module: T) => {
    const queryKey = {
        all: [module],

        queries: () => [...queryKey.all, "query"],
        query: (body: QueryBody) => [...queryKey.queries(), body],

        details: () => [...queryKey.all, "detail"],
        detail: (id: string) => [...queryKey.details(), id],
    } as const;

    return queryKey;
};
