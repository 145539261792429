import { Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { ErrorNavigation } from "modules/Core/error/ErrorNavigation";
import { ErrorWrapper } from "modules/Core/error/ErrorWrapper";
import { NotFoundSvg } from "shared/components/svg/NotFoundSvg";

export const NotFoundPage = () => {
    const theme = useMantineTheme();

    return (
        <ErrorWrapper>
            <NotFoundSvg />
            <Stack align="center" gap={theme.spacing["3xl"]}>
                <Stack align="center" gap="lg">
                    <Title c="var(--mantine-color-primary-color)" order={1}>
                        Page Not Found
                    </Title>
                    <Text>The page you are looking for could not be found.</Text>
                </Stack>
                <ErrorNavigation />
            </Stack>
        </ErrorWrapper>
    );
};
