import { pointSchema } from "shared/types/geojson";
import { mapToZodLiteralUnion, moduleZodRawShape } from "shared/utils/zod";
import { match } from "ts-pattern";
import { z } from "zod";

export const hydrantColorKeySchema = z.enum(["red", "orange", "yellow", "green", "blue", "purple", "black", "silver"]);
export const nfpaHydrantClassSchema = mapToZodLiteralUnion(["classAA", "classA", "classB", "classC"] as const);

export const hydrantColorSchema = z.object({
    id: z.string(),
    color: hydrantColorKeySchema,
    description: z.string().nullable().default(null),
    default: z.boolean(),
});

export type HydrantColor = z.infer<typeof hydrantColorSchema>;

export const hydrantStatusSchema = z
    .object({
        default: z.boolean().default(false),
    })
    .extend(moduleZodRawShape);

export const hydrantTypeSchema = z
    .object({
        default: z.boolean().default(false),
    })
    .extend(moduleZodRawShape);

export const hydrantSchema = z.object({
    id: z.string().default(""),
    location: pointSchema,
    color: hydrantColorKeySchema,
    locationDescription: z.string().nullish(),
    mainSize: z.number().nullish(),
    street: z.string().nullish(),
    crossStreets: z.string().nullish(),
    type: hydrantTypeSchema.nullish(),
    status: hydrantStatusSchema.nullish(),
    code: z.string().nullish(),
    flowRate: z.number().nullish().nullable(),
    organizationId: z.string().default(""),
    customFields: z.any().default({}),
    nfpaHydrantClass: nfpaHydrantClassSchema.nullish(),
});

export function getNfpaHydrantClassDisplayText(nfpaClass: z.infer<typeof nfpaHydrantClassSchema>): string {
    return match(nfpaClass)
        .with("classAA", () => "AA | 1,500+ GPM")
        .with("classA", () => "A | 1,000 - 1,499 GPM")
        .with("classB", () => "B | 500 - 999 GPM")
        .with("classC", () => "C | Less than 500 GPM")
        .exhaustive();
}

export type Hydrant = z.infer<typeof hydrantSchema>;
export type HydrantType = z.infer<typeof hydrantTypeSchema>;
export type HydrantStatus = z.infer<typeof hydrantStatusSchema>;
