import React from "react";
import { Navigate } from "react-router-dom";
import { useIsSessionValid } from "shared/stores/userStore";
import { useModuleNav } from "shared/utils/module";

const SessionGuard = (props: { children: React.ReactNode }) => {
    const coreRoutes = useModuleNav("core");
    const hasValidSession = useIsSessionValid();

    if (!hasValidSession) return <Navigate replace to={coreRoutes.login()} />;

    return <>{props.children}</>;
};

export const withSessionGuard = (el: React.JSX.Element) => {
    return <SessionGuard>{el}</SessionGuard>;
};
