import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Ranks",
    slug: "rank",
    resource: "personnelRank",
    queryKeys: {
        tableData: "personnelRankTable",
    },
    ingresses: {
        base: () => "" as const,
        new: () => "/new" as const,
        edit: (rankId: string) => `/${rankId}` as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["personnelRank"],
        hiddenUnlessNested: true,
    },
    routes: [
        <Route
            key="personnelRankTable"
            index
            Component={lazy(() =>
                import("modules/PersonnelRank/PersonnelRankTable").then((module) => ({ default: module.PersonnelRankTable })),
            )}
        />,
        <Route
            key="personnelRankDetail"
            path=":rankId"
            Component={lazy(() =>
                import("modules/PersonnelRank/PersonnelRankBuilder/PersonnelRankBuilder").then((module) => ({
                    default: module.PersonnelRankBuilder,
                })),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
