import { z } from "zod";

export const coordinatesSchema = z
    .number()
    .array()
    .transform((shp) => shp.slice(0, 2) as [number, number]);

export const pointSchema = z.object({
    type: z.literal("Point"),
    coordinates: coordinatesSchema,
});

export type Centroid = z.infer<typeof pointSchema>;

const boundarySchema = z.object({
    type: z.literal("Polygon"),
    properties: z.object({}),
    // geojson Point[][]
    coordinates: z.array(z.array(z.tuple([z.number(), z.number()]))),
});

export type Boundary = z.infer<typeof boundarySchema>;

export const polygonSchema = z.object({
    type: z.literal("Polygon"),
    coordinates: z
        .tuple([z.number({ description: "lng" }), z.number({ description: "lat" })])
        .array()
        .array(),
});

export const multipolygonSchema = z.object({
    type: z.literal("Polygon"),
    coordinates: z
        .tuple([z.number({ description: "lng" }), z.number({ description: "lat" })])
        .array()
        .array()
        .array(),
});
