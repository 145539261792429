import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Service Positions",
    slug: "personnel-service-positions",
    resource: "personnelServicePosition",
    queryKeys: {
        tableData: "personnelServicePositionTableData",
    },
    ingresses: {
        base: () => "" as const,
        new: () => "/new" as const,
        edit: (servicePositionID: string) => `/${servicePositionID}` as const,
    },
    sidebar: {
        visibilityRequiresReadAccessToAnyOf: ["personnelServicePosition"],
        hiddenUnlessNested: true,
    },
    routes: [
        <Route
            key="personnelServicePositionTable"
            index
            Component={lazy(() =>
                import("modules/PersonnelServicePosition/PersonnelServicePositionTable").then((module) => ({
                    default: module.PersonnelServicePositionTable,
                })),
            )}
        />,
        <Route
            key="personnelServicePositionDetail"
            path=":positionId"
            Component={lazy(() =>
                import("modules/PersonnelServicePosition/PersonnelServicePositionBuilder/PersonnelServicePositionBuilder").then(
                    (module) => ({ default: module.PersonnelServicePositionBuilder }),
                ),
            )}
        />,
    ],
} as const satisfies ModuleSpec;
