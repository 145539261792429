import { BulkDeleteModal } from "shared/mantine/modals/BulkDeleteModal";
import { DeleteModal } from "shared/mantine/modals/DeleteModal";

export const modals = {
    delete: DeleteModal,
    ["bulk-delete"]: BulkDeleteModal,
};

declare module "@mantine/modals" {
    export interface MantineModalsOverride {
        modals: typeof modals;
    }
}
