import { lazy } from "react";
import { Route } from "react-router-dom";
import { ModuleSpec } from "shared/types/ModuleSpec";

export default {
    devOnly: false,
    name: "Audit Logs",
    slug: "audit",
    queryKeys: {},
    ingresses: {
        base: () => `/`,
    },
    routes: [
        <Route
            key="auditTable"
            index
            Component={lazy(() => import("modules/Audit/AuditLog").then((module) => ({ default: module.AuditTable })))}
        />,
    ],
    sidebar: {
        hiddenUnlessNested: true,
        visibilityRequiresReadAccessToAnyOf: ["audit"],
    },
} as const satisfies ModuleSpec;
